import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
// @mui
import {
    Box,
    Button,
    Card,
    Container,
    Divider,
    FormControlLabel,
    IconButton,
    Switch,
    Table,
    TableBody,
    TableContainer,
    TablePagination,
    Tooltip
} from '@mui/material';
// routes
// import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import { useSelector } from 'react-redux';
// eslint-disable-next-line import/no-unresolved
import { useSnackbar } from 'notistack';
// eslint-disable-next-line import/no-unresolved
import moment from 'moment';
import ConfirmDialogue from 'src/components/dialouge/ConfirmDialogue';
import FilterTaskModal from 'src/components/filterModal/filterTaskModal';
import AxiosAll from 'src/services/axiosAll';
import useSettings from '../../hooks/useSettings';
import useTable from '../../hooks/useTable';
import useTabs from '../../hooks/useTabs';
// _mock_
// components
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Iconify from '../../components/Iconify';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import { TableHeadCustom, TableNoData, TableSelectedActions, TableSkeleton } from '../../components/table';
// sections
import { TaskTableRow, TaskTableToolbar } from '../../sections/@dashboard/task/list';

// ----------------------------------------------------------------------

const STATUS_OPTIONS = ['all', 'active', 'banned'];

const ROLE_OPTIONS = [
    'all',
    'ux designer',
    'full stack designer',
    'backend developer',
    'project manager',
    'leader',
    'ui designer',
    'ui/ux designer',
    'front end developer',
    'full stack developer',
];

const TABLE_HEAD = [
    // { id: 'id', label: 'Id', align: 'left' },
    { id: 'title', label: 'Title', align: 'left' },
    { id: 'project', label: 'project', align: 'left' },
    { id: 'dueDate', label: 'Due Date', align: 'left' },
    { id: 'priority', label: 'priority', align: 'center' },
    { id: 'status', label: 'Status', align: 'left' },
    { id: 'dev', label: 'Developer', align: 'left' },
    { id: 'creator', label: 'Creator', align: 'left' },
    { id: 'time', label: 'Alloted Time', align: 'left' },
    { id: 'spend', label: 'Spend Time', align: 'left' },
    // { id: 'parent', label: 'Parent task', align: 'left' },
    { id: 'action', label: 'Action', align: 'left' },
];

// ----------------------------------------------------------------------

export default function TeamTasks() {
    const {
        dense,
        page,
        order,
        orderBy,
        rowsPerPage,
        setPage,
        //
        selected,
        setSelected,
        onSelectRow,
        onSelectAllRows,
        //
        onSort,
        onChangeDense,
        onChangePage,
        onChangeRowsPerPage,
    } = useTable();

    const { themeStretch } = useSettings();

    const navigate = useNavigate();
    // get redux state
    const { userDetails } = useSelector((state) => state.loginReducer);
    const { token } = userDetails;
    const { user } = userDetails;

    const [tableData, setTableData] = useState([]);

    const [filterTitle, setFilterTitle] = useState('');

    const [filterDeadline, setFilterDeadline] = useState('all');
    const params = useParams();

    const { currentTab: filterStatus, onChangeTab: onChangeFilterStatus } = useTabs('all');
    const [loading, setLoading] = useState(false);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [deleteId, setDeleteId] = useState('');
    const [count, setCount] = useState(0);
    const { enqueueSnackbar } = useSnackbar();
    const [multipleDeleteMode, setMultipleDeleteMode] = useState(false);
    const [project, setProject] = useState();
    const [users, setUser] = useState();
    const [userID, setUserID] = useState('');
    const [projectId, setProjectId] = useState('');
    const [dateFilter, setDate] = useState();
    const [StatusId, setStatusId] = useState(params?.status ? params?.status : '');
    const [status, setStatus] = useState()
    const [priorities, setPriorities] = useState()
    const [prioritieId, setPrioritieId] = useState('')

    const handleFilterName = (filterTitle) => {
        setFilterTitle(filterTitle);
        setPage(0);
    };

    const handleFilterRole = (event) => {
        setFilterDeadline(event.target.value);
    };

    const getTasks = async () => {
        setLoading(true);
        const response = await AxiosAll('get', `/tasks?page=${page + 1}&type=team_task&query=${filterTitle}&project_id=${projectId}&user_id=${userID}&status=${StatusId}&priority=${prioritieId}&due_date=${(dateFilter && moment(dateFilter).format('YYYY-MM-DD')) || ''}`, {}, token);
        const { data } = response;

        if (data?.statusCode === 200) {
            setLoading(false);
            setTableData(data?.data?.data);
            setCount(response?.data?.data?.total);
        }
    };

    const getUser = async () => {
        setUser([]);
        const response = await AxiosAll('get', '/common/users', {}, token);
        if (response?.status === 200) {
            setUser(response?.data);
        }
    };

    const getProject = async () => {
        setProject([]);
        const response = await AxiosAll('get', `/common/projects?type=add_log&user_id=${userID}`, {}, token);
        if (response?.status === 200) {
            setProject(response?.data);
        }
    };

    const getStatus = async () => {
        setStatus([]);
        const response = await AxiosAll('get', `/task-statuses`, {}, token);
        if (response?.status === 200) {
            setStatus(response?.data?.data?.data);
        }
    };

    const getPrioritie = async () => {
        setPriorities([]);
        const response = await AxiosAll('get', `/task-priorities`, {}, token);
        if (response?.status === 200) {
            setPriorities(response?.data?.data?.data);
        }
    };

    useEffect(() => {
        getTasks();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, filterTitle, projectId, userID, StatusId, prioritieId, projectId]);


    const handleConfirmDelete = () => {
        if (selected.length > 0 && multipleDeleteMode) {
            handleDeleteRows(selected);
        } else {
            handleDeleteRow(deleteId);
        }
        setDeleteDialog(false);
    };
    const handleDeleteRow = async (id) => {
        const res = await AxiosAll('delete', `/tasks/${id}`, null, token)
        const { data } = res;
        if (data?.statusCode === 200) {
            enqueueSnackbar(data?.message, { variant: 'success' });
            getTasks();
        }
        else if (data?.statusCode === 419) {
            enqueueSnackbar(data?.message, { variant: 'error' });
        }
    };

    // bulk delete is yet to complete.
    const handleDeleteRows = async (selected) => {
        const response = await AxiosAll('delete', `/tasks`, { ids: selected }, token);
        const { data } = response;
        if (data?.statusCode === 200) {
            enqueueSnackbar(data?.message, { variant: 'success' });
            setSelected([]);
            getTasks();
        } else {
            enqueueSnackbar(data?.message, { variant: 'error' });
        }
    };

    const handleEditRow = (id) => {
        navigate(`/team-tasks/edit/${id}`);
    };
    const handleAddSub = (id) => {
        navigate(`/tasks/add-sub/${id}`);
    };


    useEffect(() => {
        getUser();
    }, []);

    useEffect(() => {
        getProject();
    }, [userID]);

    useEffect(() => {
        getStatus();
    }, []);

    useEffect(() => {
        getPrioritie()
    }, [])


    const denseHeight = dense ? 52 : 72;

    const isNotFound =
        (!tableData.length && !!filterTitle) ||
        (!tableData.length && !!filterDeadline) ||
        (!tableData.length && !!filterStatus);

    return (
        <Page title="User: List">
            <Container maxWidth={themeStretch ? false : 'lg'}>
                <HeaderBreadcrumbs
                    heading="Team Task List"
                    links={[{ name: 'Dashboard', href: '/dashboard' }, { name: 'Task List' }]}
                    action={
                        <Button
                            variant="contained"
                            component={RouterLink}
                            to={'/team-tasks/add-task'}
                            startIcon={<Iconify icon={'eva:plus-fill'} />}
                        >
                            Add Task
                        </Button>
                    }
                />
                <FilterTaskModal
                    setStatusId={setStatusId}
                    setProjectID={setProjectId}
                    setDate={setDate}
                    dateFilter={dateFilter}
                    setUserID={setUserID}
                    projectId={projectId}
                    userID={userID}
                    project={project}
                    status={status}
                    StatusId={StatusId}
                    users={users}
                    priorities={priorities}
                    setPrioritieId={setPrioritieId}

                />
                <Card>
                    <Divider />

                    <ConfirmDialogue
                        open={deleteDialog}
                        loading={loading}
                        onClose={() => setDeleteDialog(false)}
                        onConfirm={() => handleConfirmDelete(deleteId)}
                        title={`Are you sure you want to delete ${multipleDeleteMode && selected.length > 1 ? 'these tasks' : 'this task'
                            }?`}
                        content="This action cannot be undone. Please confirm to proceed."
                    />
                    <TaskTableToolbar
                        filterTitle={filterTitle}
                        filterDeadline={filterDeadline}
                        onFilterName={handleFilterName}
                        onFilterRole={handleFilterRole}
                        optionsRole={ROLE_OPTIONS}
                        search="task"
                    />

                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
                            {selected.length > 0 && (
                                <TableSelectedActions
                                    dense={dense}
                                    numSelected={selected.length}
                                    rowCount={tableData.length}
                                    onSelectAllRows={(checked) =>
                                        onSelectAllRows(
                                            checked,
                                            tableData.map((row) => row.id)
                                        )
                                    }
                                    actions={
                                        <Tooltip title="Delete">
                                            <IconButton color="primary" onClick={() => {
                                                setDeleteDialog(true);
                                                setMultipleDeleteMode(true);
                                            }}
                                            >
                                                <Iconify icon={'eva:trash-2-outline'} />
                                            </IconButton>
                                        </Tooltip>
                                    }
                                />
                            )}

                            <Table size={dense ? 'small' : 'medium'}>
                                <TableHeadCustom
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={tableData.length}
                                    numSelected={selected.length}
                                    onSort={onSort}
                                    onSelectAllRows={(checked) =>
                                        onSelectAllRows(
                                            checked,
                                            tableData.map((row) => row.id)
                                        )
                                    }
                                />
                                {loading ? (
                                    <>
                                        <TableSkeleton />
                                        <TableSkeleton />
                                        <TableSkeleton />
                                        <TableSkeleton />
                                        <TableSkeleton />
                                    </>
                                ) : (
                                    <TableBody>
                                        {tableData.map((row) => (
                                            <TaskTableRow
                                                teamTask
                                                key={row.id}
                                                row={row}
                                                setDeleteDialog={setDeleteDialog}
                                                setMultipleDeleteMode={setMultipleDeleteMode}
                                                setDeleteId={setDeleteId}
                                                selected={selected.includes(row.id)}
                                                onSelectRow={() => onSelectRow(row.id)}
                                                // onDeleteRow={() => handleDeleteRow(row.id)}
                                                onEditRow={() => handleEditRow(row.id)}
                                                onAddSub={() => handleAddSub(row?.id)}
                                            />
                                        ))}

                                        {/* <TableEmptyRows height={denseHeight} emptyRows={emptyRows(page, rowsPerPage, tableData.length)} /> */}

                                        <TableNoData isNotFound={isNotFound} />
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <Box sx={{ position: 'relative' }}>
                        <TablePagination
                            rowsPerPageOptions={[20]}
                            component="div"
                            count={count}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={onChangePage}
                            onRowsPerPageChange={onChangeRowsPerPage}
                        />

                        <FormControlLabel
                            control={<Switch checked={dense} onChange={onChangeDense} />}
                            label="Dense"
                            sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
                        />
                    </Box>
                </Card>
            </Container>
        </Page >
    );
}
