/* eslint-disable eqeqeq */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import InputMask from 'react-input-mask';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
// @mui
import { LoadingButton } from '@mui/lab';
import { Box, Card, Checkbox, Grid, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
// routes
// import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
// eslint-disable-next-line import/no-unresolved
// eslint-disable-next-line import/no-unresolved
import axios from 'axios';
import moment from 'moment';
import AxiosAll from 'src/services/axiosAll';
import { FormProvider, RHFEditor, RHFSelect, RHFTextField } from '../../../components/hook-form';
// ----------------------------------------------------------------------

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

// ----------------------------------------------------------------------

TaskNewAddForm.propTypes = {
  isSub: PropTypes.bool,
  task: PropTypes.object,
};

export default function TaskNewAddForm({ isSub, task }) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const [priorityData, setPriorityData] = useState([]);
  const [projectData, setProjectData] = useState([]);
  const [allTask, setAllTask] = useState([]);
  const [taskStatus, setTaskStatus] = useState([]);
  const [userData, setUserData] = useState([]);
  const [teamWithUser, setTeamWithUser] = useState([]);
  const [selected, setSelected] = useState([]);
  const [selArray, setSelArray] = useState([]);
  const [selLevel, setSelLevel] = useState([]);
  const [selTime, setSelTime] = useState([]);
  const { userDetails } = useSelector((state) => state.loginReducer);

  const isLead = userDetails?.user?.roles?.some((role) => role.id === 3);

  // get redux state
  const { token } = userDetails;

  const NewProductSchema = Yup.object().shape({
    // project: Yup.string().required('Project is required'),
    task: Yup.string().required('Task is required'),
    visiblity: Yup.string().required('Startup visiblity is required'),
    // status: Yup.string().required('Status is required'),
    // predecessorTask: Yup.string().required('Predecessor task is required'),
    // successorTask: Yup.string().required('Successor task is required'),
    // description: Yup.string().required('Description is required'),
    // assignUser: Yup.string().required('Assign user is required'),
    // priority: Yup.string().required('Priroty is required'),
    plannedHour: Yup.string().min(5, 'Must be in the form of HH:MM').required('Planned hour is required'),
    startDate: Yup.string().required('Start date is required'),
    // dueDate: Yup.string().required('Due date is required'),
    // teams: Yup.string().required('Teams is required'),
    // refUrl: Yup.string().required('URL is required'),
    // refTitle: Yup.string().required('Refrence title is required'),
  });

  const defaultValues = useMemo(
    () => ({
      task: task?.title || '',
      description: '',
      plannedHour: task?.planned_time_format || '01:00',
      refUrl: task?.reference_url || '',
      refTitle: task?.reference_title || '',
      startDate: task?.start_date || '',
      dueDate: task?.due_date || '',

      project: task?.project || projectData[0]?.id,
      status: task?.task_status?.id || taskStatus[0]?.id,
      predecessorTask: '',
      successorTask: '',
      assignUser: '',
      priority: task?.task_priority?.name || priorityData[0]?.id,
      visiblity: task?.startup_visibility || 'visible',
      siblings: task?.has_siblings || '0',
      parentTask: '',
      // selectUser: selectedUser,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [projectData, taskStatus, allTask, userData, priorityData]
  );
  const defaultEditValues = useMemo(
    () => ({
      task: task?.title,
      // description: task?.description,
      plannedHour: task?.planned_time_format,
      refUrl: task?.reference_url,
      refTitle: task?.reference_title,
      startDate: task?.start_date,
      dueDate: task?.due_date,

      project: task?.project_id || projectData[0]?.id,
      status: task?.task_status?.id || taskStatus[0]?.id,
      predecessorTask: task?.predecessor_task_id || allTask[0]?.id,
      successorTask: task?.following_task_id || allTask[0]?.id,
      priority: task?.task_priority?.id || priorityData[0]?.id,
      visiblity: task?.startup_visibility || 'visible',
      siblings: task?.has_siblings || '0',
      assignUser: userData[0]?.id || '',
      parentTask: task?.parent_task_id || allTask[0]?.id,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [projectData, taskStatus, allTask, userData, priorityData, task]
  );
  useEffect(() => {
    if (isSub && task) {
      // eslint-disable-next-line array-callback-return
      task?.assigned_user?.map((item, index) => {
        setValue('assignUser', item?.id);
      });
    }
  }, [isSub, task]);

  const methods = useForm({
    resolver: yupResolver(NewProductSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    control,
    setValue,
    getValues,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();
  // console.log('values', values);

  // Common dropdowns
  const getPriority = async () => {
    const res = await AxiosAll('get', '/common/task-priorities', {}, token);
    if (res?.status === 200) {
      const newData = res?.data;
      setPriorityData(newData);
    }
  };
  const getProjects = async () => {
    const res = await AxiosAll('get', '/common/projects', {}, token);
    if (res?.status === 200) {
      const newData = res?.data;
      setProjectData(newData);
    }
  };
  const getTask = async () => {
    const res = await AxiosAll('get', `/common/tasks?project_id=${values?.project}`, {}, token);
    if (res?.status === 200) {
      const newData = res?.data;
      setAllTask(newData);
    }
  };
  const getStatus = async () => {
    const res = await AxiosAll('get', '/common/task-statuses', {}, token);
    if (res?.status === 200) {
      const newData = res?.data;
      setTaskStatus(newData);
    }
  };
  const getUser = async () => {
    const res = await AxiosAll('get', `/common/users`, {}, token);
    if (res?.status === 200) {
      const newData = res?.data;
      setUserData(newData);
    }
  };
  const getTeamWithUser = async () => {
    const res = await AxiosAll('get', `/common/teamsWithUsers`, {}, token);
    if (res?.status === 200) {
      const newData = res?.data;
      setTeamWithUser(newData);
    }
  };
  useEffect(() => {
    getProjects();
    // getTask();
    getPriority();
    getStatus();
    getUser();
    getTeamWithUser();
  }, []);

  // All user finalized on 1/12/22
  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    values?.project && getTask();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.project]);

  const assignUser = async () => {
    if (task?.assigned_user?.length !== 0) {
      if (isSub && task) {
        const assignedUser = await task?.assigned_user?.map((item) => item.id);
        await setValue('assignUser', `${assignedUser[0]}`);
      }
    }
  };

  const handleTimeChange = (e, onChange) => {
    const value = e.target.value;
    // increase hours by 1 if minutes are greater than 60
    if (value.split(':')[1] > 59) {
      // if hours is less than 10, add 0 before it
      const hours =
        parseInt(value.split(':')[0], 10) + 1 < 10
          ? `0${parseInt(value.split(':')[0], 10) + 1}`
          : parseInt(value.split(':')[0], 10) + 1;
      // if minutes is less than 10, add 0 before it
      const minutes =
        parseInt(value.split(':')[1], 10) - 60 < 10
          ? `0${parseInt(value.split(':')[1], 10) - 60}`
          : parseInt(value.split(':')[1], 10) - 60;
      onChange(`${hours}:${minutes}`);
    } else {
      onChange(value);
    }
  };

  const handleSelectTeam = (id) => {
    const selectedIndex = selected.indexOf(id);

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };
  // console.log(selected);

  const handleSelectUser = (e, item) => {
    const userId = e.target.value;
    const teamId = item?.id;
    const teamIndex = selected.indexOf(teamId);

    setSelArray((prev) => {
      const newSelArray = [...prev];
      // make object of teamId and userId only if teamId is selected
      if (teamIndex !== -1) {
        newSelArray[teamIndex] = { team_id: teamId, user_id: userId };
      }
      return newSelArray;
    });
  };
  const handleSelectLevel = (e, item) => {
    const Level = e.target.value;
    const teamId = item?.id;
    const teamIndex = selected.indexOf(teamId);

    setSelLevel((prev) => {
      const newSelArray = [...prev];
      // make object of teamId and userId only if teamId is selected
      if (teamIndex !== -1) {
        newSelArray[teamIndex] = { team_id: teamId, level: Level };
      }
      return newSelArray;
    });
  };

  const handleSubTimeChange = (e, onChange, item) => {
    const value = e.target.value;
    // increase hours by 1 if minutes are greater than 60
    if (value.split(':')[1] > 59) {
      // if hours is less than 10, add 0 before it
      const hours =
        parseInt(value.split(':')[0], 10) + 1 < 10
          ? `0${parseInt(value.split(':')[0], 10) + 1}`
          : parseInt(value.split(':')[0], 10) + 1;
      // if minutes is less than 10, add 0 before it
      const minutes =
        parseInt(value.split(':')[1], 10) - 60 < 10
          ? `0${parseInt(value.split(':')[1], 10) - 60}`
          : parseInt(value.split(':')[1], 10) - 60;
      onChange(`${hours}:${minutes}`);
      const timeSub = `${hours}:${minutes}`;
      // eslint-disable-next-line no-unused-expressions
      handleSelectTime(timeSub, item);
    } else {
      onChange(value);
      handleSelectTime(value, item)
    }
  };
  const handleSelectTime = (timeSub, item) => {
    const sel_time = timeSub;
    const teamId = item?.id;
    const teamIndex = selected.indexOf(teamId);

    setSelTime((prev) => {
      const newSelArray = [...prev];
      // make object of teamId and userId only if teamId is selected
      if (teamIndex !== -1) {
        newSelArray[teamIndex] = { team_id: teamId, time: sel_time };
      }
      return newSelArray;
    });
  };
  // console.log('selected array', selLevel);

  const addAssignUser = async () => {
    if (userData?.length !== 0) {
      await setValue('assignUser', `${userData[0]?.id}`);
    }
  };

  useEffect(() => {
    addAssignUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  useEffect(() => {
    assignUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSub, task]);

  const afterOrderCheck = async () => {
    try {
      const formData = new FormData();
      formData.append('project_id', values?.project);
      formData.append('title', values?.task);
      formData.append('status', values?.status);
      formData.append('description', values?.description);
      formData.append('startup_visibility', values?.visiblity);
      formData.append('start_date', moment(values.startDate).format('YYYY-MM-DD'));
      // eslint-disable-next-line no-unused-expressions
      values?.siblings === '0' && formData.append('due_date', moment(values?.dueDate).format('YYYY-MM-DD'));
      formData.append('predecessor_task_id', values?.predecessorTask);
      formData.append('following_task_id', values?.successorTask);
      // eslint-disable-next-line no-unused-expressions
      values?.siblings === '0' && formData.append('assigned_user_id', values?.assignUser);
      formData.append('priority', values?.priority);
      // eslint-disable-next-line no-unused-expressions
      values?.siblings === '0' && formData.append('planned_time_format', values?.plannedHour);
      formData.append('reference_url', values?.refUrl);
      formData.append('reference_title', values?.refTitle);
      formData.append('parent_task_id', values?.parentTask);
      formData.append('has_siblings', values?.siblings);

      // remove object if team id is not present in selected array
      const filteredPeople = selArray.filter((item) => selected.includes(item?.team_id));
      // pass object to formData
      const subTaskObj = {};
      filteredPeople.forEach((item, index) => {
        subTaskObj[`subTasks[${index}][team_id]`] = item.team_id;
        subTaskObj[`subTasks[${index}][user_id]`] = item.user_id;
      });
      Object.keys(subTaskObj).forEach((key) => {
        // eslint-disable-next-line no-unused-expressions
        values?.siblings === '1' && formData.append(key, subTaskObj[key]);
      });



      // Level selected
      const filteredLevel = selLevel.filter((item) => selected.includes(item?.team_id));
      // pass object to formData
      const subTaskLevelObj = {};
      filteredLevel.forEach((item, index) => {
        subTaskLevelObj[`subTasks[${index}][level]`] = item.level;
      });
      Object.keys(subTaskLevelObj).forEach((key) => {
        // eslint-disable-next-line no-unused-expressions
        values?.siblings === '1' && formData.append(key, subTaskLevelObj[key]);
      });

      // Selected Time
      const filteredTime = selTime.filter((item) => selected.includes(item?.team_id));
      // pass object to formData
      const subTaskTimeObj = {};
      filteredTime.forEach((item, index) => {
        subTaskTimeObj[`subTasks[${index}][time]`] = item.time;
      });
      Object.keys(subTaskTimeObj).forEach((key) => {
        // eslint-disable-next-line no-unused-expressions
        values?.siblings === '1' && formData.append(key, subTaskTimeObj[key]);
      });



      const createStartupURL = `https://staging.pms.skunktest.work/api/tasks`;
      // const createStartupURL = `https://staging.pms.skunktest`;
      // const updateStartupURL = `https://staging.pms.skunktest.work/api/tasks/${params.id}`;

      const response = await axios.post(createStartupURL, formData, {
        headers: {
          contentType: 'multipart/form-data',
          Authorization: `Bearer ${userDetails.token}`,
        },
      });
      const { data } = response;
      if (data.statusCode === 200) {
        enqueueSnackbar(data?.message, { variant: 'success' });
        if (isLead) {
          navigate('/team-tasks', { replace: true });
        } else {
          navigate('/tasks', { replace: true });
        }
        setSelected([]);
        reset();
      }
      reset();
    } catch (error) {
      console.error(error);
      const { data } = error?.response;
      enqueueSnackbar(data?.message, { variant: 'error' });
    }
  }
  const onSubmit = async () => {

    if (values?.siblings === '1') {
      if (!(selLevel.filter(value => value?.level == 1).length > 0)) {
        enqueueSnackbar("Please choose sub task of level 1.", { variant: 'error' });
      } else if (!(selLevel.filter(value => value?.level == 2).length > 0) && (selLevel.filter(value => value?.level == 3).length > 0)) {
        enqueueSnackbar("Please choose sub task of level 2.", { variant: 'error' });
      } else if (!(selLevel.filter(value => value?.level == 3).length > 0) && (selLevel.filter(value => value?.level == 4).length > 0)) {
        enqueueSnackbar("Please choose sub task of level 3.", { variant: 'error' });
      } else {
        afterOrderCheck();
      }
    } else if (values?.siblings === '0' && values?.assignUser === '') {
      enqueueSnackbar("Please assign user", { variant: 'error' });
    } else {
      afterOrderCheck();
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (isSub && task) {
      reset(defaultEditValues);
    }
    if (!isSub && task) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task, taskStatus, priorityData, projectData]);

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Card sx={{ p: 3 }}>
            <Stack spacing={3}>
              <div>
                <LabelStyle>Project*</LabelStyle>
                {isSub ? (
                  task?.project?.title
                ) : (
                  <RHFSelect name="project">
                    {projectData?.map((item, index) => (
                      <option value={item?.id} key={index}>
                        {item?.title}
                      </option>
                    ))}
                  </RHFSelect>
                )}
              </div>
              <div>
                <LabelStyle>Task*</LabelStyle>
                <RHFTextField name="task" label="Please describe task.." />
              </div>
              <div>
                <LabelStyle>Status*</LabelStyle>
                <RHFSelect name="status">
                  {taskStatus?.map((item, index) => (
                    <option value={item?.id} key={index}>
                      {item?.name}
                    </option>
                  ))}
                </RHFSelect>
              </div>
              <div>
                <LabelStyle>Predecessor Tasks</LabelStyle>
                <RHFSelect name="predecessorTask">
                  <option value={''}>Select</option>
                  {allTask?.map(
                    (item, index) =>
                      task?.id !== item?.id &&
                      item?.id != values?.successorTask && (
                        <option value={item?.id} key={index}>
                          {item?.title}
                        </option>
                      )
                  )}
                </RHFSelect>
              </div>
              <div>
                <LabelStyle>Successor Tasks</LabelStyle>
                <RHFSelect name="successorTask">
                  <option value={''}>Select</option>
                  {allTask?.map(
                    (item, index) =>
                      task?.id !== item?.id &&
                      item?.id != values?.predecessorTask && (
                        <option value={item?.id} key={index}>
                          {item?.title}
                        </option>
                      )
                  )}
                </RHFSelect>
              </div>
              <div>
                <LabelStyle>Description</LabelStyle>
                {/* <RHFTextField name="description" label="Write Something Awesome..." multiline rows={4} /> */}
                <RHFEditor simple name="description" />
              </div>
              <div>
                <LabelStyle>Has child task?</LabelStyle>
                <RHFSelect name="siblings">
                  <option value="0">{'No'}</option>
                  <option value="1">{'Yes'}</option>
                </RHFSelect>
              </div>
              {values?.siblings === '0' ? null : (
                <Box>
                  Teams
                  {teamWithUser?.map((item, index) => (
                    <div
                      key={index}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        margin: '10px 0',
                      }}
                    >
                      <Checkbox checked={selected?.includes(item?.id)} onClick={() => handleSelectTeam(item?.id)} />
                      <Typography variant="body2" sx={{ width: 200, ml: 1 }}>
                        {item?.name}
                      </Typography>
                      <div style={{ width: '45%' }}>
                        <LabelStyle>Assign User</LabelStyle>
                        <RHFSelect name={`selectedUser${item?.id}`} onChange={(e) => handleSelectUser(e, item)}>
                          <option value={''}>{ }</option>
                          <option value={''}>Unassigned</option>
                          {item?.team_users?.map((userObj, index) => (
                            <option value={userObj?.user?.id} key={index}>
                              {userObj?.user?.first_name} {userObj?.user?.last_name}
                            </option>
                          ))}
                        </RHFSelect>
                      </div>

                      <div style={{ width: '15%' }}>
                        <LabelStyle>Level</LabelStyle>
                        <RHFSelect name={`selectedLevel${item?.id}`} onChange={(e) => handleSelectLevel(e, item)}>
                          <option value={''}>{ }</option>
                          <option value={'1'}>1</option>
                          <option value={'2'}>2</option>
                          <option value={'3'}>3</option>
                          <option value={'4'}>4</option>
                        </RHFSelect>
                      </div>
                      <div style={{ width: '25%' }}>
                        <LabelStyle>Time</LabelStyle>
                        <Controller
                          name={`selectedTime${item?.id}`}
                          control={control}
                          render={({ field, fieldState: { error } }) => (
                            <InputMask maskChar={null} mask="99:99" value={field.value} onChange={(e) => handleSubTimeChange(e, field.onChange, item)}>
                              {(inputProps) => (
                                <TextField
                                  {...inputProps}
                                  error={!!error}
                                  fullWidth
                                  helperText={error?.message}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <Typography variant="body2">HH:MM</Typography>
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              )}
                            </InputMask>
                          )}
                        />
                      </div>
                      {/* <div style={{ width: '20%' }}>
                        <RHFTextField name={`selectedTime${item?.id}`} type="number" onChange={(e) => handleSelectTime(e, item)}>{ }</RHFTextField>
                      </div> */}
                      {/* <Controller
                          key={index}
                          name="sel"
                          control={control}
                          render={({ field, fieldState: { error } }) => (
                            <Autocomplete
                              {...field}
                              multiple
                              freeSolo
                              getOptionLabel={(option) => `${option?.user?.first_name} ${option?.user?.last_name}`}
                              onChange={(event, newValue, reason, detail) => {
                                if (reason === 'removeOption') {
                                  const updatedArray = selectedUser.filter(el => el?.user !== detail?.option?.user_id);
                                  console.log("detail", updatedArray);
                                  setSelectedUser(updatedArray);
                                } else {
                                  setSelectedUser((prev) => [...prev, { team: newValue[newValue.length - 1]?.team_id, user: newValue[newValue.length - 1]?.user_id, }])
                                }
                                // console.log('new value', reason)
                                field.onChange(newValue);
                              }}
                              getOptionDisabled={(option) => option}
                              options={item?.team_users}
                              renderTags={(tagValue, getTagProps) =>
                                tagValue.map((option, index) => (
                                  <Chip key={option?.user_id} label={`${option?.user?.first_name} ${option?.user?.last_name}`} {...getTagProps({ index })} />
                                ))
                              }
                              renderInput={(params) => <TextField {...params} error={!!error} helperText={error?.message} />}
                            />
                          )}
                        /> */}
                      <br />
                    </div>
                  ))}
                </Box>
              )}
              {/* We can choose 1 user at creating task. and no need to send team by gajendre sir 11/11 */}
              {values?.siblings === '0' ? (
                <div>
                  <LabelStyle>Assign User</LabelStyle>
                  <RHFSelect name="assignUser">
                    <option value={''}>Select</option>
                    {userData?.map((item, index) => (
                      <option value={item?.id} key={index}>{`${item?.first_name} ${item?.last_name}`}</option>
                    ))}
                  </RHFSelect>
                </div>
              ) : null}
              {isSub && (
                <div>
                  <LabelStyle>Parent task</LabelStyle>
                  <RHFSelect name="parentTask">
                    {allTask?.map((item, index) => (
                      <option value={item?.id} key={index}>
                        {item?.title}
                      </option>
                    ))}
                  </RHFSelect>
                </div>
              )}
            </Stack>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Stack spacing={3}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={3} mt={2}>
                <div>
                  <LabelStyle>Priority*</LabelStyle>
                  <RHFSelect name="priority">
                    {priorityData?.map((item, index) => (
                      <option value={item?.id} key={index}>
                        {item?.name}
                      </option>
                    ))}
                  </RHFSelect>
                </div>

                <div>
                  <LabelStyle>Startup Visiblity*</LabelStyle>
                  <RHFSelect name="visiblity">
                    <option value="visible">Visible</option>
                    <option value="hidden">Hidden</option>
                  </RHFSelect>
                </div>

                {values?.siblings === '0' && <div>
                  <LabelStyle>Estimate time (in hours and minutes)*</LabelStyle>
                  <Controller
                    name="plannedHour"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <InputMask maskChar={null} mask="99:99" value={field.value} onChange={(e) => handleTimeChange(e, field.onChange)}>
                        {(inputProps) => (
                          <TextField
                            {...inputProps}
                            error={!!error}
                            fullWidth
                            helperText={error?.message}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Typography variant="body2">HH:MM</Typography>
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      </InputMask>
                    )}
                  />
                </div>}

                <div>
                  <LabelStyle>Start Date*</LabelStyle>
                  <RHFTextField name="startDate" type="date" />
                </div>
                {values?.siblings === '0' && <div>
                  <LabelStyle>Due Date</LabelStyle>
                  <RHFTextField name="dueDate" type="date" min={values?.startDate} />
                </div>}

                <div>
                  <LabelStyle>Reference URL</LabelStyle>
                  <RHFTextField name="refUrl" />
                </div>

                <div>
                  <LabelStyle>Reference Title</LabelStyle>
                  <RHFTextField name="refTitle" />
                </div>
              </Stack>
            </Card>

            <LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting}>
              {'Create Task'}
            </LoadingButton>
          </Stack>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
