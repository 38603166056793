import React, { useEffect, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
// @mui
import { Container, MenuItem, TextField } from '@mui/material';
// routes
// import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import { useSelector } from 'react-redux';
// eslint-disable-next-line import/no-unresolved
import AxiosAll from 'src/services/axiosAll';
// import { Calendar, momentLocalizer } from 'react-big-calendar';
import FullCalendar from '@fullcalendar/react'; // must go before plugins
//
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import listGridPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';
import moment from 'moment';
// eslint-disable-next-line import/no-unresolved, import/extensions
import 'react-big-calendar/lib/sass/styles.scss';
// eslint-disable-next-line import/no-unresolved, import/extensions
import 'react-big-calendar/lib/css/react-big-calendar.css';

import { Stack } from '@mui/system';
import Iconify from 'src/components/Iconify';
import useSettings from '../../hooks/useSettings';
import useTable, { getComparator } from '../../hooks/useTable';
import useTabs from '../../hooks/useTabs';
// _mock_
// components
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Page from '../../components/Page';
// sections

const ColoredDateCellWrapper = ({ children }) =>
  // eslint-disable-next-line no-undef
  React.cloneElement(React.Children.only(children), {
    style: {
      backgroundColor: 'lightblue',
    },
  });

export default function TimeSheet() {
  const { page, order, orderBy } = useTable();

  const { themeStretch } = useSettings();

  const { userDetails } = useSelector((state) => state.loginReducer);
  const location = useLocation();
  const { token, user } = userDetails;
  const navigate = useNavigate();
  // const localizer = momentLocalizer(moment);
  const { state } = location;
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [allTask, setAllTask] = useState([]);
  const [filterId, setFilterId] = useState('');
  const [timeSheet, setTimeSheet] = useState();
  const [myEvents, setMyEvents] = useState([]);
  const [filterWork, setFilterWork] = useState('all');
  const [users, setUser] = useState();
  const [userId, setUserId] = useState();
  const [project, setProject] = useState();
  const [projectId, setProjectId] = useState();
  const [tasks, setTask] = useState();
  const [taskId, setTaskId] = useState();
  const [startDate, setStartDate] = useState(
    moment(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)).format('YYYY-MM-DD')
  );
  const [endDate, setEndDate] = useState(moment(new Date()).format('YYYY-MM-DD'));

  const { currentTab: filterStatus, onChangeTab: onChangeFilterStatus } = useTabs('all');

  const getLoges = async () => {
    // setLoading(true);
    const response = await AxiosAll(
      'get',
      `/calendar-logs?start_date=${state?.start_date ?? moment(startDate).format('YYYY-MM-DD')}&end_date=${state?.due_date ?? moment(endDate).format('YYYY-MM-DD')
      }&user_id=${state?.userId || userId || ''}&project_id=${projectId || ''}&task_id=${taskId || ''}`,
      {},
      token
    );
    if (response?.data?.statusCode === 200 && response.data.data?.length) {
      // eslint-disable-next-line no-debugger
      const afterMap = response.data.data.map((value, index) => ({
        title: value.task_name,
        date: value.date,
        groupId: value.task_id,
        type: value?.type,
        extendedProps: {
          time_text: `${Math.floor(value?.time / 60)} hour ${Math.round(
            (value?.time / 60 - Math.floor(value?.time / 60)) * 60
          )} minute`,
          currentDate: new Date(value.date),
        },
      }));
      setMyEvents(afterMap);
      // do not add duplicate data
      // setLoading(false);
    } else {
      setMyEvents([]);
    }
  };

  const EditTimeSheet = (data) => {
    // eslint-disable-next-line no-debugger
    console.log("dghj", data?.event?.groupId == null);
    if (data?.event?.groupId !== "null") {
      navigate(`/logs?task_id=${data?.event?.groupId}&date=${moment(data?.event?.extendedProps.currentDate).format('YYYY-MM-DD')}`)
    } else {
      console.log("here", data?.event?.extendedProps?.type);
      navigate(`/logs?date=${moment(data?.event?.extendedProps.currentDate).format('YYYY-MM-DD')}&type=${data?.event?.extendedProps?.type}`);
    }
    // eslint-disable-next-line no-unused-expressions
    // data?.event?.groupId ? navigate(`/logs?task_id=${data?.event?.groupId}&date=${moment(data?.event?.extendedProps?.currentDate).format('YYYY-MM-DD')}&type=${data?.event?.extendedProps?.type}`) : navigate(`/logs?date=${moment(data?.event?.extendedProps.currentDate).format('YYYY-MM-DD')}&type=${data?.event?.type}`);
  };

  const dataFiltered = applySortFilter({
    tableData,
    comparator: getComparator(order, orderBy),
    filterId,
    filterWork,
    filterStatus,
  });

  const getUser = async () => {
    const response = await AxiosAll('get', '/common/users', {}, token);
    if (response?.status === 200) {
      setUser(response?.data);
    }
  };
  const handleUsers = (e) => {
    // eslint-disable-next-line no-debugger
    setUserId(e.target.value);
  };

  const getProject = async () => {
    const response = await AxiosAll('get', `/common/projects?user_id=${userId || ''}`, {}, token);
    if (response?.status === 200) {
      setProject(response?.data);
    }
  };

  const getTask = async () => {
    const response = await AxiosAll('get', `/common/tasks?project_id=${projectId || ''}`, {}, token);
    if (response?.status === 200) {
      setTask(response?.data);
    }
  };

  const handleReset = () => {
    window.location.reload();
    setTaskId('');
    setProjectId('');
    setUserId('');
  };

  useEffect(() => {
    getProject();
  }, [userId, user]);

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    getTask();
  }, [projectId]);

  useEffect(() => {
    if (userId || startDate || endDate || projectId || taskId) {
      getLoges();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, startDate, endDate, projectId, taskId]);

  useEffect(() => {
    setTableData(allTask);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allTask, page]);

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const handleProject = (e) => {
    // eslint-disable-next-line no-debugger
    setProjectId(e.target.value);
  };

  const handleTaskId = (e) => {
    // eslint-disable-next-line no-debugger
    setTaskId(e.target.value);
  };

  return (
    <Page title="User: List">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Time Sheet"
          links={[
            { name: 'Dashboard', href: '/dashboard' },
            { name: 'Time Sheet', href: '/time-sheet' },
          ]}
        />
        <Stack spacing={2} className="stack-center" direction={{ xs: 'column', sm: 'row' }} sx={{ py: 2.5, px: 3 }}>
          {user?.roles[0]?.id === 1 ? (
            <TextField
              fullWidth
              select
              label="User"
              defaultValue={''}
              onChange={handleUsers}
              SelectProps={{
                MenuProps: {
                  sx: { '& .MuiPaper-root': { maxHeight: 260 } },
                },
              }}
              sx={{
                maxWidth: { sm: 240 },
                textTransform: 'capitalize',
              }}
            >
              <MenuItem
                sx={{
                  mx: 1,
                  my: 0.5,
                  borderRadius: 0.75,
                  typography: 'body2',
                  textTransform: 'capitalize',
                }}
                key={''}
                value={''}
              >
                Select
              </MenuItem>
              {users?.map((option) => (
                <MenuItem
                  key={option?.id}
                  value={option?.id}
                  sx={{
                    mx: 1,
                    my: 0.5,
                    borderRadius: 0.75,
                    typography: 'body2',
                    textTransform: 'capitalize',
                  }}
                >
                  {option?.first_name} {option?.last_name}
                </MenuItem>
              ))}
            </TextField>
          ) : null}
          <TextField
            fullWidth
            select
            label="Project"
            value={projectId}
            onChange={handleProject}
            SelectProps={{
              MenuProps: {
                sx: { '& .MuiPaper-root': { maxHeight: 260 } },
              },
            }}
            sx={{
              maxWidth: { sm: 240 },
              textTransform: 'capitalize',
            }}
          >
            <MenuItem
              sx={{
                mx: 1,
                my: 0.5,
                borderRadius: 0.75,
                typography: 'body2',
                textTransform: 'capitalize',
              }}
              key={''}
              value={''}
            >
              Select
            </MenuItem>
            {project?.map((option) => (
              <MenuItem
                key={option?.id}
                value={option?.id}
                sx={{
                  mx: 1,
                  my: 0.5,
                  borderRadius: 0.75,
                  typography: 'body2',
                  textTransform: 'capitalize',
                }}
              >
                {option?.title}
              </MenuItem>
            ))}
          </TextField>{' '}
          <TextField
            fullWidth
            select
            label="Task"
            value={taskId}
            onChange={handleTaskId}
            SelectProps={{
              MenuProps: {
                sx: { '& .MuiPaper-root': { maxHeight: 260 } },
              },
            }}
            sx={{
              maxWidth: { sm: 240 },
              textTransform: 'capitalize',
            }}
          >
            <MenuItem
              sx={{
                mx: 1,
                my: 0.5,
                borderRadius: 0.75,
                typography: 'body2',
                textTransform: 'capitalize',
              }}
              key={''}
              value={''}
            >
              Select
            </MenuItem>
            {tasks?.map((option) => (
              <MenuItem
                key={option?.id}
                value={option?.id}
                sx={{
                  mx: 1,
                  my: 0.5,
                  borderRadius: 0.75,
                  typography: 'body2',
                  textTransform: 'capitalize',
                }}
              >
                {option?.title}
              </MenuItem>
            ))}
          </TextField>
          <div>
            <TextField id="date1" type="date" label="Start Date" value={startDate} onChange={handleStartDateChange} />
          </div>{' '}
          <div>
            <TextField id="date2" type="date" label="End Date" value={endDate} onChange={handleEndDateChange} />
          </div>
          <div>
            <Iconify
              onClick={handleReset}
              icon={'system-uicons:reset-alt'}
              className="Filter"
              width={30}
              height={30}
              sx={{ mb: 2 }}
            />
          </div>
        </Stack>{' '}
        <div style={{ height: 700 }}>
          <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, listGridPlugin]}
            initialView="dayGridMonth"
            eventContent={renderEventContent}
            events={myEvents}
            eventClick={EditTimeSheet}
            headerToolbar={{
              left: 'prev,next',
              center: 'title',
              right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
            }}
          />
        </div>
      </Container>
    </Page>
  );
}

// ----------------------------------------------------------------------

function applySortFilter({ tableData, comparator, filterId, filterStatus, filterWork }) {
  const stabilizedThis = tableData.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  tableData = stabilizedThis.map((el) => el[0]);

  if (filterId) {
    tableData = tableData.filter((item) => item.id.toLowerCase().indexOf(filterId.toLowerCase()) !== -1);
  }

  if (filterStatus !== 'all') {
    tableData = tableData.filter((item) => item.status === filterStatus);
  }

  if (filterWork !== 'all') {
    tableData = tableData.filter((item) => item.work === filterWork);
  }

  return tableData;
}

function renderEventContent(eventInfo) {
  return (
    <>
      <b>{eventInfo.event.title}</b>
      <br />
      <b>{eventInfo.event.extendedProps.time_text}</b>
    </>
  );
}
