import { Route, Routes } from 'react-router';
import CreateProject from 'src/pages/project/Create';
import ProjectDetails from 'src/pages/project/Details';
import ProjectList from 'src/pages/project/List';
import Reports from 'src/pages/reports';
import ReportsProjectDetail from 'src/pages/reports/Details';
import RoleList from 'src/pages/roles/List';
import TeamList from 'src/pages/teams/List';
import UserAccount from 'src/pages/user/UserAccount';
import UserCreate from 'src/pages/user/UserCreate';
import UserList from 'src/pages/user/UserList';
// import StartupCreate from 'src/pages/Startup/Create';
import Activities from 'src/pages/activities/Activities';
import Dashboard from 'src/pages/dashboard/dashboard';
import FavouriteTasks from 'src/pages/favourite_tasks/FavouriteTasks';
import Logs from 'src/pages/logs/list';
import Page404 from 'src/pages/Page404';
import EditProfile from 'src/pages/profile/EditProfile';
import Profile from 'src/pages/profile/Profile';
import ProjectStatus from 'src/pages/project_status/List';
import ReviewTask from 'src/pages/review';
import StartupCreate from 'src/pages/Startup/Create';
import StartupList from 'src/pages/Startup/List';
import CreateTask from 'src/pages/tasks/addTasks';
import Tasks from 'src/pages/tasks/tasks';
import TeamTasks from 'src/pages/tasks/TeamTasks';
import TaskPriorities from 'src/pages/task_priorities/List';
import TaskStatus from 'src/pages/task_status/List';
import TimeSheet from 'src/pages/timeSheet';
import Tracker from 'src/pages/tracker';
import SingleTaskDetail from 'src/sections/@dashboard/task/SingleTaskDetail';

const AdminRoutes = ({ role }) => (
  // Admin routes for Team Lead and Admin
  // Lead has role id 3 and admin has 1
  <Routes>
    {role === 3 ? (
      <>
        <Route path={'dashboard'} element={<Dashboard />} />
        <Route path={'/'} element={<Dashboard />} />
        <Route path={'profile'} element={<Profile />} />
        <Route path={'profile/edit'} element={<EditProfile />} />
        <Route path={'activities'} element={<Activities />} />
        <Route path={'favourite-tasks'} element={<FavouriteTasks />} />
        <Route path={'tasks'} element={<Tasks roleId={role} />} />
        <Route path={'tasks/:status'} element={<Tasks roleId={role} />} />
        <Route path={'team-tasks'} element={<TeamTasks />} />
        <Route path={'team-tasks/:status'} element={<TeamTasks />} />
        <Route path={'team-tasks/add-task'} element={<CreateTask />} />
        <Route path={'tasks/edit/:id'} element={<CreateTask />} />
        {/* <Route path={'tasks/add-sub/:id'} element={<CreateTask />} /> */}
        <Route path={'team-tasks/edit/:id'} element={<CreateTask />} />
        <Route path={'team-tasks/details/:id'} element={<SingleTaskDetail />} />
        <Route path={'tasks/details/:id'} element={<SingleTaskDetail />} />
        <Route path={'logs'} element={<Logs />} />
        <Route path={'review'} element={<ReviewTask />} />
        <Route path={'time-sheet'} element={<TimeSheet />} />
        <Route path={'tracker'} element={<Tracker />} />
        <Route path={'*'} element={<Page404 />} />
      </>
    ) : (
      <>
        <Route path={'dashboard'} element={<Dashboard />} />
        <Route path={'/'} element={<Dashboard />} />
        <Route path={'profile'} element={<Profile />} />
        <Route path={'profile/edit'} element={<EditProfile />} />
        <Route path={'activities'} element={<Activities />} />
        <Route path={'tasks'} element={<Tasks roleId={role} />} />
        <Route path={'tasks/:status'} element={<Tasks roleId={role} />} />
        <Route path={'tasks/add-task'} element={<CreateTask />} />
        <Route path={'tasks/add-sub/:id'} element={<CreateTask />} />
        <Route path={'tasks/edit/:id'} element={<CreateTask />} />
        <Route path={'tasks/details/:id'} element={<SingleTaskDetail />} />
        <Route path={'favourite-tasks'} element={<FavouriteTasks />} />
        <Route path={'logs'} element={<Logs />} />
        <Route path={'review'} element={<ReviewTask />} />
        <Route path={'time-sheet'} element={<TimeSheet />} />
        <Route path={'tracker'} element={<Tracker />} />
        <Route path={'startup/create-startup'} element={<StartupCreate />} />
        <Route path={'startup/startup-list'} element={<StartupList />} />
        <Route path={'startup/edit/:id'} element={<StartupCreate />} />
        <Route path={'project/create-project'} element={<CreateProject />} />
        <Route path={'project/project-list'} element={<ProjectList />} />
        <Route path={'project/details/:id'} element={<ProjectDetails />} />
        <Route path={'project/edit/:id'} element={<CreateProject />} />
        <Route path={'reports'} element={<Reports />} />
        <Route path={'reports/project/:id'} element={<ReportsProjectDetail />} />
        <Route path={'manage-user/create-user'} element={<UserCreate />} />
        <Route path={'manage-user/edit-user/:id'} element={<UserCreate />} />
        <Route path={'user/user-list'} element={<UserList />} />
        <Route path={'user/user-account'} element={<UserAccount />} />

        <Route path={'settings/team-list'} element={<TeamList />} />
        {/* <Route path={'settings/tag-list'} element={<TagList />} /> */}
        <Route path={'settings/role-list'} element={<RoleList />} />
        <Route path={'settings/project-status'} element={<ProjectStatus />} />
        <Route path={'settings/task-status'} element={<TaskStatus />} />
        <Route path={'settings/task-priorities'} element={<TaskPriorities />} />
        <Route path={'*'} element={<Page404 />} />
      </>
    )}
  </Routes>
);

export default AdminRoutes;
