import { InputAdornment, Stack, TextField } from '@mui/material';
import PropTypes from 'prop-types';
// components
import { useState } from 'react';
// eslint-disable-next-line import/no-unresolved
import { useSelector } from 'react-redux';
// eslint-disable-next-line import/no-unresolved
import Iconify from '../../../../components/Iconify';

// ----------------------------------------------------------------------

UserTableToolbar.propTypes = {
  filterTitle: PropTypes.string,
  filterDeadline: PropTypes.string,
  onFilterName: PropTypes.func,
  onFilterRole: PropTypes.func,
  optionsRole: PropTypes.arrayOf(PropTypes.string),
  setProjectID: PropTypes.string,
  setTaskID: PropTypes.string,
  setDate: PropTypes.string,
  dateFilter: PropTypes.string,
  setUserID: PropTypes.string,
};

export default function UserTableToolbar({
  filterTitle,
  filterDeadline,
  onFilterName,
  onFilterRole,
  optionsRole,
  setProjectID,
  setTaskID,
  setDate,
  dateFilter,
  setUserID,
  search,
  setQuery,
}) {
  const [project, setProject] = useState();
  const [projectId, setProjectId] = useState(0);
  const [tasks, setTask] = useState();
  const [users, setUser] = useState();
  const [userId, setUserId] = useState();
  const { userDetails } = useSelector((state) => state.loginReducer);
  const { token, user } = userDetails;

  const handleUsers = (e) => {
    // eslint-disable-next-line no-debugger
    setUserId(e.target.value);
    setUserID(e.target.value);
    // setProjectID(e.target.value);
  };

  const handleProject = (e) => {
    // eslint-disable-next-line no-debugger
    setProjectId(e.target.value);
    setProjectID(e.target.value);
  };

  const handleTaskId = (e) => {
    // eslint-disable-next-line no-debugger
    setTaskID(e.target.value);
  };

  const handleDateChange = (e) => {
    setDate(e.target.value);
  };
  // const getUser = async () => {
  //   const response = await AxiosAll('get', '/users', {}, token);
  //   console.log('user', response?.data);
  //   if (response?.status === 200) {
  //     setUser(response?.data?.data?.data);
  //   }
  // };

  // const getProject = async () => {
  //   const response = await AxiosAll('get', `/common/projects?user_id=${userId || ''}`, {}, token);
  //   console.log('project', response?.data);
  //   if (response?.status === 200) {
  //     setProject(response?.data);
  //   }
  // };

  // const getTask = async () => {
  //   if (projectId && projectId !== 'undefined') {
  //     const response = await AxiosAll('get', `/common/tasks?project_id=${projectId || ''}`, {}, token);
  //     console.log('task', response?.data);
  //     if (response?.status === 200) {
  //       setTask(response?.data);
  //     }
  //   }
  // };

  // useEffect(() => {
  //   getUser();
  // }, []);

  // useEffect(() => {
  //   getProject();
  // }, [userId]);

  // useEffect(() => {
  //   if (projectId && project?.length) {
  //     getTask();
  //   }
  // }, [projectId]);

  return (
    <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }} sx={{ py: 2.5, px: 3 }}>
      {/* {user?.roles[0]?.name === 'admin' ? (
        <TextField
          fullWidth
          select
          label="User"
          value={filterDeadline}
          onChange={handleUsers}
          SelectProps={{
            MenuProps: {
              sx: { '& .MuiPaper-root': { maxHeight: 260 } },
            },
          }}
          sx={{
            maxWidth: { sm: 240 },
            textTransform: 'capitalize',
          }}
        >
          {users?.map((option) => (
            <MenuItem
              key={option?.id}
              value={option?.id}
              sx={{
                mx: 1,
                my: 0.5,
                borderRadius: 0.75,
                typography: 'body2',
                textTransform: 'capitalize',
              }}
            >
              {option?.first_name} {option?.last_name}
            </MenuItem>
          ))}
        </TextField>
      ) : null}{' '} */}
      {/* <TextField
        fullWidth
        select
        label="Project"
        value={filterDeadline}
        onChange={handleProject}
        SelectProps={{
          MenuProps: {
            sx: { '& .MuiPaper-root': { maxHeight: 260 } },
          },
        }}
        sx={{
          maxWidth: { sm: 240 },
          textTransform: 'capitalize',
        }}
      >
        {project?.map((option) => (
          <MenuItem
            key={option?.id}
            value={option?.id}
            sx={{
              mx: 1,
              my: 0.5,
              borderRadius: 0.75,
              typography: 'body2',
              textTransform: 'capitalize',
            }}
          >
            {option?.title}
          </MenuItem>
        ))}
      </TextField>{' '}
      <TextField
        fullWidth
        select
        label="Task"
        value={filterDeadline}
        onChange={handleTaskId}
        SelectProps={{
          MenuProps: {
            sx: { '& .MuiPaper-root': { maxHeight: 260 } },
          },
        }}
        sx={{
          maxWidth: { sm: 240 },
          textTransform: 'capitalize',
        }}
      >
        {tasks?.map((option) => (
          <MenuItem
            key={option?.id}
            value={option?.id}
            sx={{
              mx: 1,
              my: 0.5,
              borderRadius: 0.75,
              typography: 'body2',
              textTransform: 'capitalize',
            }}
          >
            {option?.title}
          </MenuItem>
        ))}
      </TextField>
      <div>
        <TextField id="date" type="date" value={dateFilter} onChange={handleDateChange} />;
      </div> */}
      <TextField
        fullWidth
        value={filterTitle}
        onChange={(event) => setQuery(event.target.value)}
        placeholder={`Search ${search ?? `user`}...`}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify icon={'eva:search-fill'} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
            </InputAdornment>
          ),
        }}
      />
    </Stack>
  );
}
