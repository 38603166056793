import { capitalCase } from 'change-case';
import { useLocation, useParams } from 'react-router-dom';
// @mui
import { Container } from '@mui/material';
// routes
// components
// eslint-disable-next-line import/no-unresolved
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import AxiosAll from 'src/services/axiosAll';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Page from '../../components/Page';
import ProjectNewEditForm from '../../sections/@dashboard/project/ProjectNewEditForm';
// sections

// ----------------------------------------------------------------------

export default function CreateProject() {
  // const { themeStretch } = useSettings();

  const params = useParams();
  const { pathname } = useLocation();
  const { name = '' } = params;
  const isEdit = pathname.includes('edit');
  const [currentProject, setCurrentProject] = useState();
  const { userDetails } = useSelector((state) => state.loginReducer);

  const getProject = async () => {
    if (isEdit) {
      const response = await AxiosAll('get', `/projects/${params.id}`, null, userDetails.token);
      const { data } = response;
      setCurrentProject(data?.data);
    }
    return {};
  };

  useEffect(() => {
    getProject();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page title="Project: Create a new Project">
      <Container maxWidth={true ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={!isEdit ? 'Create a new project' : 'Edit project'}
          links={[
            { name: 'Dashboard', href: '/dashboard' },
            { name: 'Projects', href: '/project/project-list' },
            { name: !isEdit ? 'New Project' : capitalCase(name) },
          ]}
        />

        <ProjectNewEditForm isEdit={isEdit} currentProject={currentProject} />
      </Container>
    </Page>
  );
}
