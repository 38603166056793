import { LOGIN, LOGOUT } from '../constant';
// action CRUD for Login data

export const addLogin = (logindata) => {
  return { type: LOGIN, data: logindata };
};

export const deleteLogin = () => {
  return { type: LOGOUT };
};
