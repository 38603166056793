import { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-duplicates
import { useLocation } from 'react-router-dom';
// @mui
import {
  Box, Button, Card, Container, Divider, FormControlLabel, Switch, Table, TableBody, TablePagination
} from '@mui/material';
// routes
// import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import { useSelector } from 'react-redux';
// eslint-disable-next-line import/no-duplicates

// eslint-disable-next-line import/no-unresolved
import moment from 'moment';
import FilterModal from 'src/components/filterModal/indx';
import AxiosAll from 'src/services/axiosAll';
import useSettings from '../../hooks/useSettings';
import useTable from '../../hooks/useTable';
import useTabs from '../../hooks/useTabs';
// _mock_
// components
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Iconify from '../../components/Iconify';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import {
  TableHeadCustom,
  TableNoData, TableSkeleton
} from '../../components/table';
// sections
import { LogTableRow, LogTableToolbar } from '../../sections/@dashboard/logs/list';
import AddLogsForm from './addLogs';
import EditLogsForm from './EditLog';

const TABLE_HEAD = [
  { id: 'id', label: 'Developer', align: 'left' },
  { id: 'type', label: 'Log Type', align: 'left' },
  { id: 'task_title', label: 'Task Title', align: 'left' },
  { id: 'work', label: 'Work', align: 'left' },
  { id: 'time', label: 'Time#', align: 'center' },
  { id: 'date', label: 'Date', align: 'left' },
  { id: 'action', label: 'Action', align: 'left' },
];

// ----------------------------------------------------------------------

export default function Logs() {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const { themeStretch } = useSettings();

  const { userDetails } = useSelector((state) => state.loginReducer);
  const { token } = userDetails;
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const taskID = urlParams.get('task_id') || '';
  const date = urlParams.get('date');
  const type = urlParams.get('type')

  const location = useLocation();
  const { state } = location;
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [projectId, setProjectId] = useState('');
  const [taskId, setTaskId] = useState('');
  const [filterId, setFilterId] = useState('');
  const [count, setCount] = useState(0);
  const [editTask, setEditTask] = useState();
  const [dateFilter, setDate] = useState(date);
  const [filterWork, setFilterWork] = useState('all');
  const [userID, setUserID] = useState('');
  const [query, setQuery] = useState();
  const [project, setProject] = useState();
  const [tasks, setTask] = useState();
  const [users, setUser] = useState();
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const { currentTab: filterStatus, onChangeTab: onChangeFilterStatus } = useTabs('all');

  const handleFilterName = (filterId) => {
    setFilterId(filterId);
    setPage(0);
  };

  const handleFilterRole = (event) => {
    setFilterWork(event.target.value);
  };

  const getLogesLead = async () => {
    setLoading(true);
    setTableData([]);
    const response = await AxiosAll(
      'get',
      `/task-time-logs?query=${query || ''}&page=${page + 1}&project_id=${projectId}&type=team_log&user_id=${userID}&type=${type}&task_id=${taskId || taskID
      }&date=${(dateFilter && moment(dateFilter).format('YYYY-MM-DD')) || ''}`,
      {},
      token
    );
    if (response?.data?.statusCode === 200) {
      // do not add duplicate data
      setLoading(false);

      // setAllTask(response?.data?.data?.data);
      setTableData(response?.data?.data?.data);
      setCount(response?.data?.data?.total);
    } else {
      setCount();
      setLoading(false);
    }
  };
  const getLoges = async () => {
    setLoading(true);
    setTableData([]);
    const response = await AxiosAll(
      'get',
      `/task-time-logs?query=${query || ''}&page=${page + 1}&project_id=${projectId}&user_id=${userID}&type=${type}&task_id=${taskId || taskID
      }&date=${(dateFilter && moment(dateFilter).format('YYYY-MM-DD')) || ''}`,
      {},
      token
    );
    if (response?.data?.statusCode === 200) {
      // do not add duplicate data
      setLoading(false);

      // setAllTask(response?.data?.data?.data);
      setTableData(response?.data?.data?.data);
      setCount(response?.data?.data?.total);
    } else {
      setCount();
      setLoading(false);
    }
  };

  const handleEditRow = (data) => {
    setEditOpen(true);
    setEditTask(data);
  };

  const denseHeight = dense ? 52 : 72;

  const getUser = async () => {
    setUser([]);
    const response = await AxiosAll('get', '/common/users', {}, token);
    if (response?.status === 200) {
      setUser(response?.data);
    }
  };

  const getProject = async () => {
    setProject([]);
    const response = await AxiosAll('get', `/common/projects?type=add_log&user_id=${userID}`, {}, token);
    if (response?.status === 200) {
      setProject(response?.data);
    }
  };

  const getTask = async () => {
    setTask([]);
    const response = await AxiosAll('get', `/common/tasks?project_id=${projectId}`, {}, token);
    if (response?.status === 200) {
      await setTask(response?.data);
    }
  };

  const handleAddOpen = () => {
    setOpen(true)
    setEditTask("")
  }

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    getProject();
  }, [userID]);

  useEffect(() => {
    getTask();
  }, [projectId]);

  const isNotFound = (!tableData?.length && !!filterId) || (!tableData?.length && !!filterWork) || (!tableData?.length && !!filterStatus);

  useEffect(() => {
    if (userDetails?.user?.roles[0]?.id === 2) {
      getLoges(dateFilter)
    } else {
      getLogesLead(dateFilter)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskID, date, open, editOpen, query, projectId, taskId, dateFilter, setDate, userID, page]);

  return (
    <Page title="User: List">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Log List"
          links={[{ name: 'Dashboard', href: '/dashboard' }, { name: 'Logs List' }]}
          action={
            <Button variant="contained" onClick={handleAddOpen} startIcon={<Iconify icon={'eva:plus-fill'} />}>
              Add Logs
            </Button>
          }
        />

        <FilterModal
          setTaskID={setTaskId}
          setProjectID={setProjectId}
          setDate={setDate}
          dateFilter={dateFilter}
          setUserID={setUserID}
          projectId={projectId}
          userID={userID}
          project={project}
          tasks={tasks}
          users={users}
        />
        <Card className="main_container">
          <Divider />
          <AddLogsForm
            open={open}
            onClose={() => setOpen(false)}
            onNextStep={() => setOpen(false)}
          />

          <EditLogsForm
            open={editOpen}
            editTask={editTask}
            onClose={() => setEditOpen(false)}
            onNextStep={() => setEditOpen(false)} />

          <LogTableToolbar
            filterId={filterId}
            filterWork={filterWork}
            onFilterName={handleFilterName}
            onFilterRole={handleFilterRole}
            search="logs"
            setQuery={setQuery}
          />

          <Scrollbar>
            {/* <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              {selected.length > 0 && (
                <TableSelectedActions
                  dense={dense}
                  numSelected={selected.length}
                  rowCount={tableData.length}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      tableData.map((row) => row.id) 
                    )
                  }
                  actions={
                    <Tooltip title="Delete">
                      <IconButton color="primary" onClick={() => handleDeleteRows(selected)}>
                        <Iconify icon={'eva:trash-2-outline'} />
                      </IconButton>
                    </Tooltip>
                  }
                />
              )} */}

            <Table size={dense ? 'small' : 'medium'}>
              <TableHeadCustom
                hideCheckbox
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={tableData?.length}
                numSelected={selected?.length}
                onSort={onSort}
                onSelectAllRows={(checked) =>
                  onSelectAllRows(
                    checked,
                    tableData.map((row) => row.id)
                  )
                }
              />
              {loading ? (
                <>
                  <TableSkeleton />
                  <TableSkeleton />
                  <TableSkeleton />
                  <TableSkeleton />
                  <TableSkeleton />
                </>
              ) : (
                <TableBody>
                  {tableData?.map((row) => (
                    <LogTableRow
                      key={row.id}
                      row={row}
                      selected={selected.includes(row.id)}
                      onSelectRow={() => onSelectRow(row.id)}
                      // onDeleteRow={() => handleDeleteRow(row.id)}
                      onEditRow={() => handleEditRow(row)}
                    />
                  ))}

                  {/* <TableEmptyRows height={denseHeight} emptyRows={emptyRows(page, rowsPerPage, tableData.length)} /> */}

                  <TableNoData isNotFound={isNotFound} />
                </TableBody>
              )}
            </Table>
            {/* </TableContainer> */}
          </Scrollbar>

          <Box sx={{ position: 'relative' }}>
            <TablePagination
              rowsPerPageOptions={[20]}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
            />
            <FormControlLabel
              control={<Switch checked={dense} onChange={onChangeDense} />}
              label="Dense"
              sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
            />
          </Box>
        </Card>
      </Container>
    </Page>
  );
}

// ----------------------------------------------------------------------

// function applySortFilter({ tableData, comparator, filterId, filterStatus, filterWork }) {
//   const stabilizedThis = tableData.map((el, index) => [el, index]);

//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) return order;
//     return a[1] - b[1];
//   });

//   tableData = stabilizedThis.map((el) => el[0]);

//   if (filterId) {
//     tableData = tableData.filter((item) => item.id.toLowerCase().indexOf(filterId.toLowerCase()) !== -1);
//   }

//   if (filterStatus !== 'all') {
//     tableData = tableData.filter((item) => item.status === filterStatus);
//   }

//   if (filterWork !== 'all') {
//     tableData = tableData.filter((item) => item.work === filterWork);
//   }

//   return tableData;
// }
