import PropTypes from 'prop-types';
import * as Yup from 'yup';
// form
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import {
    Button, Dialog, DialogActions, DialogContent, DialogTitle, InputAdornment, MenuItem, Stack, TextField, Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
// _mock
import { useEffect, useMemo, useState } from 'react';
// eslint-disable-next-line import/no-unresolved
import InputMask from 'react-input-mask';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import AxiosAll from 'src/services/axiosAll';
import { FormProvider, RHFEditor, RHFSelect, RHFTextField } from '../../components/hook-form';

// ----------------------------------------------------------------------

const LabelStyle = styled(Typography)(({ theme }) => ({
    ...theme.typography.subtitle2,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1),
}));

EditLogsForm.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onNextStep: PropTypes.func,
    onCreateBilling: PropTypes.func,
    editTask: PropTypes.object,
};

export default function EditLogsForm({ open, onClose, editTask }) {
    const NewAddressSchema = Yup.object().shape({
        time: Yup.string().min(5, 'Must be in the form of HH:MM').required('Time is required'),

        comments: Yup.string().required('Description  is required'),
        date: Yup.string().required('date is required'),
        // task: Yup.string().required('task is required'),
    });
    const typeData = [
        { id: 'discussion', title: 'Discussion' },
        { id: 'internal_meeting', title: 'Internal Meeting' },
        { id: 'external_meeting', title: 'External Meeting' },
        { id: 'holiday', title: 'Holiday' },
        { id: 'break', title: 'Break' },
        { id: 'celebration', title: 'Celebration' },
        { id: 'down_time', title: 'Down Time' },
    ]
    const defaultValues = {
        time: '',
        comments: '',
        date: '',
        task: '',
        project: '',
        type: ''
    };

    const defaultEditValues = useMemo(
        () => ({
            time: editTask?.time_format,
            comments: editTask?.comment,
            date: editTask?.date,
            task: editTask?.task?.id || '',
            project: editTask?.task?.project_id || editTask?.project_id,
            type: editTask?.type
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [editTask]
    );
    const { userDetails } = useSelector((state) => state.loginReducer);
    const { token } = userDetails;
    const [project, setProject] = useState([]);
    const [projectId, setProjectId] = useState();
    const [tasks, setTask] = useState([]);
    const [task_Id, setTask_Id] = useState();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const methods = useForm({
        resolver: yupResolver(NewAddressSchema),
        defaultValues,
    });
    const {
        reset,
        watch,
        handleSubmit,
        control,
        formState: { isSubmitting },
    } = methods;

    const values = watch()

    const handleOnClose = () => {
        reset(defaultValues)
        onClose()
    }

    useEffect(() => {
        if (editTask) {
            reset(defaultEditValues);
        }
    }, [editTask]);

    useEffect(() => {
        if (editTask && project?.length) {
            reset(defaultEditValues);
            setProjectId(editTask?.project_id)

        }
    }, [editTask && project]);


    const onSubmit = async (value) => {
        if (editTask?.id) {
            const data = {
                comment: value?.comments,
                task_id: value?.task || '',
                date: value?.date,
                time_format: value?.time,
                type: value?.type
            };
            try {
                const response = await AxiosAll('post', `/task-time-logs/${editTask?.id}`, data, token);
                if (response?.status === 200) {
                    handleOnClose()
                    navigate('/logs');
                    onClose()
                } else {
                    enqueueSnackbar(response?.data?.message, { variant: 'error' });
                }
            } catch (error) {
                enqueueSnackbar(error?.data?.message, { variant: 'error' });
            }
        }
        reset()
    };

    const getProject = async () => {
        const response = await AxiosAll('get', '/common/projects', {}, token);
        if (response?.status === 200) {
            setProject(response?.data);
        }
    };

    const getTask = async () => {
        const response = await AxiosAll('get', `/common/tasks?project_id=${values?.project || ''}`, {}, token);
        if (response?.status === 200) {
            setTask(response?.data);
        }
    };

    const handleTimeChange = (e, onChange) => {
        const value = e.target.value;
        // increase hours by 1 if minutes are greater than 60
        if (value.split(':')[1] > 59) {
            // if hours is less than 10, add 0 before it
            const hours =
                parseInt(value.split(':')[0], 10) + 1 < 10
                    ? `0${parseInt(value.split(':')[0], 10) + 1}`
                    : parseInt(value.split(':')[0], 10) + 1;
            // if minutes is less than 10, add 0 before it
            const minutes =
                parseInt(value.split(':')[1], 10) - 60 < 10
                    ? `0${parseInt(value.split(':')[1], 10) - 60}`
                    : parseInt(value.split(':')[1], 10) - 60;
            onChange(`${hours}:${minutes}`);
        } else {
            onChange(value);
        }
    };
    const handleTaskId = (e) => {
        // eslint-disable-next-line no-debugger
        setTask_Id(e.target.value);
    };

    useEffect(() => {
        // eslint-disable-next-line no-unused-expressions
        open && getProject();
    }, [open]);

    useEffect(() => {
        // eslint-disable-next-line no-debugger
        getTask();
    }, [values.project]);

    return (
        <Dialog fullWidth maxWidth="sm" open={open} onClose={handleOnClose}>
            <DialogTitle> Edit Log</DialogTitle>

            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <DialogContent>
                    <Stack spacing={3}>
                        <div>
                            <LabelStyle>Log Type</LabelStyle>
                            <RHFSelect disabled={!!editTask} name="type" >
                                {/* <option value="">Select Project</option> */}
                                {typeData?.map((data, index) => (
                                    <option key={index} value={data?.id}>
                                        {data?.title}
                                    </option>
                                ))}
                            </RHFSelect>
                        </div>

                        {(values?.type === "discussion" || values?.type === "external_meeting" || values?.type === "task_log") && <div>
                            <LabelStyle>Project*</LabelStyle>
                            <RHFSelect disabled={!!editTask} name="project" >
                                {/* <option value="">Select Project</option> */}
                                {project?.map((data, index) => (
                                    <option key={index} value={data?.id}>
                                        {data?.title}
                                    </option>
                                ))}
                            </RHFSelect>
                        </div>}

                        {values?.type === "Task Log" && <div>
                            <LabelStyle>Task*</LabelStyle>
                            <RHFSelect
                                fullWidth
                                disabled={!!editTask}
                                name="task"
                                label="task"
                                InputLabelProps={{ shrink: true }}
                                SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                            >
                                {tasks?.map((data) => (
                                    <MenuItem
                                        key={data?.id}
                                        value={data?.id}
                                        sx={{
                                            mx: 1,
                                            my: 0.5,
                                            borderRadius: 0.75,
                                            typography: 'body2',
                                            textTransform: 'capitalize',
                                        }}
                                    >
                                        {data?.title}
                                    </MenuItem>
                                ))}
                            </RHFSelect>
                        </div>}

                        <div>
                            <LabelStyle>Date*</LabelStyle>
                            <RHFTextField name="date" type="date" />
                        </div>

                        <div>
                            <LabelStyle>Time Taken*</LabelStyle>
                            <Controller
                                name="time"
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <InputMask maskChar={null} mask="99:99" value={field.value} onChange={(e) => handleTimeChange(e, field.onChange)}>
                                        {(inputProps) => (
                                            <TextField
                                                {...inputProps}
                                                error={!!error}
                                                fullWidth
                                                helperText={error?.message}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <Typography variant="body2">HH:MM</Typography>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        )}
                                    </InputMask>
                                )}
                            />
                        </div>
                        <div>
                            <LabelStyle>Description</LabelStyle>
                            {/* <RHFTextField name="description" label="Write Something Awesome..." multiline rows={4} /> */}
                            <RHFEditor simple name="comments" />
                        </div>
                    </Stack>
                </DialogContent>

                <DialogActions>
                    <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                        Submit
                    </LoadingButton>
                    <Button color="inherit" variant="outlined" onClick={handleOnClose}>
                        Cancel
                    </Button>
                </DialogActions>
            </FormProvider>
        </Dialog>
    );
}
