// @mui
import { Box, Container, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

// layouts
// eslint-disable-next-line import/no-unresolved
import NewPasswordForm from 'src/sections/auth/new-password';
// components
// sections
// assets
import { useLayoutEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
// eslint-disable-next-line import/no-unresolved
import AxiosAll from 'src/services/axiosAll';
import { SentIcon } from '../../assets';
import Page from '../../components/Page';
import LogoOnlyLayout from '../../layouts/LogoOnlyLayout';

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  // maxWidth: 480,
  // maxWidth:'33.33vw',
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function NewPassword() {
  const location = useLocation();
  const nav = useNavigate();

  const [validToken, setValidToken] = useState(false);

  const token = location.search.split('=')[1];

  const verifyToken = async () => {
    const response = await AxiosAll('post', '/verify-token', { token });
    const data = await response?.data;
    if (data?.statusCode === 200) {
      setValidToken(true);
    } else {
      nav('/404', { replace: true });
    }
  };

  useLayoutEffect(() => {
    verifyToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    validToken && (
      <Page title="New Password" className="newpassword">
        <LogoOnlyLayout />

        <Container>
          <ContentStyle sx={{ textAlign: 'center' }} className="widthcontainer container-style">
            <SentIcon sx={{ mb: 5, mx: 'auto', height: 120 }} />

            <Typography variant="h3" gutterBottom className="main_heading">
              Create a new password
            </Typography>

            {/* <Typography sx={{ color: 'text.secondary' }} className="heading_16 parapadding">
            We've sent a 6-digit confirmation email to your email.
            Please enter the code in below box to verify your email.
          </Typography> */}

            <Box sx={{ mt: 5, mb: 3 }}>
              <NewPasswordForm token={token} />
            </Box>

            {/* <Typography variant="body2" className='heading_14'>
            Don’t have a code? &nbsp;
            <Link variant="subtitle2" onClick={() => {}}>
              Resend code
            </Link>
          </Typography> */}
          </ContentStyle>
        </Container>
      </Page>
    )
  );
}
