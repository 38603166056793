import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import AxiosAll from 'src/services/axiosAll';
/* eslint-disable react-hooks/exhaustive-deps */
// @mui
import { Box, Button, Card, CardHeader, Divider, Link, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
// utils
import Markdown from 'src/components/Markdown';
import { fToNow } from '../../../../utils/formatTime';
// components
import Iconify from '../../../../components/Iconify';
import Image from '../../../../components/Image';
import Scrollbar from '../../../../components/Scrollbar';

// ----------------------------------------------------------------------

AnalyticsNewsUpdate.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  list: PropTypes.array.isRequired,
};

export default function AnalyticsNewsUpdate({ title, subheader, ...other }) {
  const { userDetails } = useSelector((state) => state.loginReducer);
  const [activityList, setActivityList] = useState([]);

  const navigate = useNavigate();

  const getActivity = async () => {
    try {
      const res = await AxiosAll('get', '/activities?limit=5', null, userDetails?.token);
      const data = res.data.data.data;
      setActivityList(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getActivity();
  }, []);

  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />

      <Scrollbar>
        <Stack spacing={3} sx={{ p: 3, pr: 0 }}>
          {activityList.map((activity) => (
            <ActivityItem key={activity.id} activity={activity} />
          ))}
        </Stack>
      </Scrollbar>

      <Divider />

      {userDetails?.user?.roles[0]?.id === 1 && <Box sx={{ p: 2, textAlign: 'right' }}>
        <Button
          onClick={() => navigate('/activities')}
          size="small"
          color="inherit"
          endIcon={<Iconify icon={'eva:arrow-ios-forward-fill'} />}
        >
          View all
        </Button>
      </Box>}
    </Card>
  );
}

// ----------------------------------------------------------------------

ActivityItem.propTypes = {
  activity: PropTypes.shape({
    activity_type: PropTypes.string,
    message: PropTypes.string,
    created_at: PropTypes.instanceOf(Date),
    activity_action_type: PropTypes.string,
    creator: PropTypes.object
  }),
};

function ActivityItem({ activity }) {
  const { activity_type, message, creator, activity_action_type, created_at } = activity;

  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      {creator ? <Image
        src={creator?.profile_picture}
        sx={{ width: 48, height: 48, borderRadius: 1.5, flexShrink: 0 }}
      /> : <Image
        src={`https://thumbs.dreamstime.com/b/default-avatar-profile-vector-user-profile-default-avatar-profile-vector-user-profile-profile-179376714.jpg`}
        sx={{ width: 48, height: 48, borderRadius: 1.5, flexShrink: 0 }}
      />}
      <Box sx={{ minWidth: 240 }}>
        <Link color="inherit" variant="subtitle2" noWrap>
          {activity_action_type} > {activity_type}
        </Link>

        <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
          <Markdown children={message} />
        </Typography>
      </Box>

      <Typography variant="caption" sx={{ pr: 3, flexShrink: 0, color: 'text.secondary' }}>
        {fToNow(created_at)}
      </Typography>
    </Stack>
  );
}
