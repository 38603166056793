import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import { Avatar, Checkbox, MenuItem, TableCell, TableRow, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// components
import moment from 'moment';
import { useNavigate } from 'react-router';
import Iconify from '../../../../components/Iconify';
import Label from '../../../../components/Label';
import { TableMoreMenu } from '../../../../components/table';

// ----------------------------------------------------------------------

UserTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onEditRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  // onDeleteRow: PropTypes.func,
  setDeleteDialog: PropTypes.func,
  setDeleteId: PropTypes.func,
  setMultipleDeleteMode: PropTypes.func,
};

export default function UserTableRow({
  row,
  selected,
  onEditRow,
  onSelectRow,
  setMultipleDeleteMode,
  // onDeleteRow,
  // onAddUser,
  // onAddManager,
  setDeleteDialog,
  setDeleteId,
}) {
  const theme = useTheme();
  const navigate = useNavigate();
  const { title } = row;

  const [openMenu, setOpenMenuActions] = useState(null);

  const handleOpenMenu = (event) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
    setMultipleDeleteMode(false);
  };

  return (
    <TableRow hover selected={selected}>
      <TableCell padding="checkbox">
        <Checkbox checked={selected} onClick={onSelectRow} />
      </TableCell>

      <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
        <Avatar alt={title} src={row?.startup_detail?.logo} sx={{ mr: 2 }} />
        <Typography
          sx={{
            cursor: 'pointer',
            px: 1,
            '&:hover': {
              backgroundColor: (theme) =>
                theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[600],
              borderRadius: 1,
            },
          }}
          onClick={() => navigate(`/project/details/${row.id}`)}
          variant="subtitle2"
          noWrap
        >
          {title}
        </Typography>
      </TableCell>

      <TableCell align="left">{moment(row?.start_date).format("D MMM YYYY")}</TableCell>
      <TableCell align="left">{moment(row?.due_date).format("D MMM YYYY")}</TableCell>

      <TableCell align="left">
        <Label
          variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
          color={row?.project_status?.color}
          sx={{ textTransform: 'capitalize' }}
        >
          <Typography
            sx={{ cursor: 'pointer' }}
            onClick={() => navigate(`project/${row.id}`)}
            variant="subtitle2"
            noWrap
          >
            {row?.project_status?.name}
          </Typography>
        </Label>
      </TableCell>

      <TableCell align="left">
        <TableMoreMenu
          open={openMenu}
          onOpen={handleOpenMenu}
          onClose={handleCloseMenu}
          actions={
            <>
              <MenuItem
                onClick={() => {
                  // onDeleteRow();
                  setDeleteDialog(true);
                  setDeleteId(row.id);
                  handleCloseMenu();
                }}
                sx={{ color: 'error.main' }}
              >
                <Iconify icon={'eva:trash-2-outline'} />
                Delete
              </MenuItem>
              <MenuItem
                onClick={() => {
                  onEditRow();
                  handleCloseMenu();
                }}
              >
                <Iconify icon={'eva:edit-fill'} />
                Edit
              </MenuItem>
              {/* <MenuItem
                onClick={() => {
                  onAddUser();
                  handleCloseMenu();
                }}
              >
                <Iconify icon={'eva:plus-circle-outline'} />
                Assign User
              </MenuItem>
              <MenuItem
                onClick={() => {
                  onAddManager();
                  handleCloseMenu();
                }}
              >
                <Iconify icon={'eva:plus-circle-outline'} />
                Add Manager
              </MenuItem> */}
            </>
          }
        />
      </TableCell>
    </TableRow>
  );
}
