// @mui
import { Container, Grid, Typography } from '@mui/material';
// import { useTheme } from '@mui/material/styles';
// hooks
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import AxiosAll from 'src/services/axiosAll';
import useSettings from '../../hooks/useSettings';
// _mock_
import { _analyticPost } from '../../_mock';
// components
import Page from '../../components/Page';
// sections
import { AnalyticsNewsUpdate, AnalyticsWidgetSummary } from '../../sections/@dashboard/general/analytics';

// ----------------------------------------------------------------------

export default function Dashboard() {
  // const theme = useTheme();

  const [dashboardData, setDashboardData] = useState([]);

  const { userDetails } = useSelector((state) => state.loginReducer);
  const { token } = userDetails;
  const [isLead, setIsLead] = useState(false);
  const { themeStretch } = useSettings();
  const nav = useNavigate();

  const getDashboardData = async () => {
    const response = await AxiosAll('get', `/dashboard`, {}, token);
    const { data } = response;
    window.scrollTo(0, 0);
    setDashboardData(data);
  };

  useEffect(() => {
    getDashboardData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    const isLead = userDetails?.user?.roles?.some((role) => role.id === 3);
    setIsLead(isLead);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails?.user?.roles]);

  const handleNavigate = async (item) => {
    nav(`/tasks/${item?.id}`);
  }

  return (
    <Page title="General: Dashboard">
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography>

        <Grid container spacing={2}>
          {dashboardData?.map((item) =>
            <Grid item sx={{ cursor: 'pointer' }} xs={12} sm={6} md={2.4} key={item?.id} onClick={() => handleNavigate(item)}>
              <AnalyticsWidgetSummary
                title={item?.name}
                color={item?.color}
                total={item?.tasks_count}
                icon={item?.icon}
              />
            </Grid>
          )}

          <Grid item xs={12} md={6} lg={10}>
            <AnalyticsNewsUpdate title="Latest Activity" list={_analyticPost} />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
