/* eslint-disable import/no-absolute-path */
/* eslint-disable import/no-unresolved */
/* eslint-disable global-require */

export const imagesAll = {
  mainLogo: '/assets/mainLogo.svg',
  rsvrLogo: '/assets/images/rsvr_logo.png',
  rsvrSquareLogo: '/assets/images/rsvrtech-squarelogo.png',
  mainWhiteLogo: '/assets/images/logo.png',
  authLogo: '/assets/images/auth_logo.png',
  facebook: '/assets/images/ic_facebbook.png',
  google: '/assets/images/ic_google.png',
  twiter: '/assets/images/twiter.png',
  girls: '/assets/images/Girl-with-interface.png',
  adminRegister: '/assets/images/Group_Analysis.png',
  error404: '/assets/images/404.png',
  error403: '/assets/images/403.png',
  error500: '/assets/images/500.png',
  maintenance: '/assets/images/maintenance.png',
  coming_soon: '/assets/images/coming_soon.png',
};
