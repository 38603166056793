import { LOGIN, LOGOUT } from '../constant';

const initialState = {
  userDetails: {},
};

// eslint-disable-next-line default-param-last
const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return { ...state, userDetails: action.data };
    case LOGOUT:
      return { ...state, userDetails: {} };

    default:
      return state;
  }
};

export default loginReducer;
