/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// @mui
import { Avatar, Card, CardContent, Container, Divider, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
// routes
// import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
// eslint-disable-next-line import/no-unresolved
// eslint-disable-next-line import/no-unresolved
import moment from 'moment';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import Markdown from 'src/components/Markdown';
import Page from 'src/components/Page';
import useSettings from 'src/hooks/useSettings';
import AxiosAll from 'src/services/axiosAll';

// ----------------------------------------------------------------------

const LabelStyle = styled(Typography)(({ theme }) => ({
    ...theme.typography.subtitle2,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1),
}));

// ----------------------------------------------------------------------

SingleTaskDetail.propTypes = {
    task: PropTypes.object,
};

export default function SingleTaskDetail() {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const params = useParams();

    const [allTask, setAllTask] = useState([]);
    const [userData, setUserData] = useState([]);
    const [task, setTask] = useState([]);
    const { themeStretch } = useSettings();
    const [predecessorTask, setPredecessorTask] = useState('');
    const [successorTask, setSuccessorTask] = useState('');
    // get redux state
    const { userDetails } = useSelector((state) => state.loginReducer);
    const { token } = userDetails;

    const getTaskDetail = async () => {
        const response = await AxiosAll('get', `/tasks/${params?.id}`, null, userDetails.token);
        const { data } = response;
        setTask(data?.data);
        return {};
    };

    useEffect(() => {
        getTaskDetail()
        getTask();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params?.id]);
    // Common dropdowns
    const getTask = async () => {
        const res = await AxiosAll('get', '/common/tasks', {}, token);
        if (res?.status === 200) {
            const newData = res?.data;
            setAllTask(newData);
        }
    };

    useEffect(() => {
        // eslint-disable-next-line eqeqeq
        const data = allTask?.find((item) => item?.id == task?.predecessor_task_id);
        setPredecessorTask(data?.title)

        // eslint-disable-next-line eqeqeq
        const data1 = allTask?.find((item) => item?.id == task?.following_task_id);
        setSuccessorTask(data1?.title)
    }, [allTask, task])


    const methods = useForm({
        resolver: yupResolver(),
    });

    const {
        reset,
        watch,
        control,
        setValue,
        getValues,
        handleSubmit,
        formState: { isSubmitting },
    } = methods;

    const values = watch();

    return (
        <Page title="Task: Detail">
            <Container maxWidth={themeStretch ? false : 'lg'}>
                <HeaderBreadcrumbs
                    heading="Task Details"
                    links={[{ name: 'Dashboard', href: '/dashboard' }, { name: 'Task list', href: '/tasks' }, { name: 'Details' }]}
                />
                <Card>
                    <Typography variant="h4" sx={{ p: 3 }}>
                        {task?.project?.title}
                    </Typography>
                    <Divider />
                    <CardContent>
                        <Grid container spacing={3}>

                            <Grid item xs={12} md={6}>
                                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                                    Project
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {task?.project?.title}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                                    Priority
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {task?.task_priority?.name}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                                    Task
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {task?.title}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                                    Startup visibility
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {task?.startup_visibility}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                                    Status
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {task?.task_status?.name}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                                    Estimated Time
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {task?.planned_time_format}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                                    Predecessor Tasks
                                </Typography>
                                {task?.predecessor_task_id &&
                                    <Typography variant="body2" color="text.secondary" sx={{ cursor: 'pointer' }} onClick={() => navigate(`/tasks/details/${task?.predecessor_task_id}`)}>
                                        {predecessorTask}
                                    </Typography>}
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                                    Start Date
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {moment(task?.start_date).format("D MMM")}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                                    Successor Tasks
                                </Typography>
                                {task?.following_task_id && <Typography variant="body2" color="text.secondary" sx={{ cursor: 'pointer' }} onClick={() => navigate(`/tasks/details/${task?.following_task_id}`)}>
                                    {successorTask}
                                </Typography>}
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                                    Due Date
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {moment(task?.due_date).format("D MMM")}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                                    Description
                                </Typography>
                                <Markdown children={task?.description} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                                    Refrence URL
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {task?.reference_url}
                                </Typography>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                                    Assigned User
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {task?.task_users?.map((user) => (
                                        <div key={user.id}>
                                            {user?.user?.first_name} {user?.user?.last_name}
                                        </div>
                                    ))}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                                    Refrence Title
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {task?.reference_title}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                                    Creator
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {task?.created_by_user?.full_name}
                                </Typography>
                            </Grid>
                            {task?.reviewer_comment && <Grid item xs={12} md={6}>
                                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                                    Task Comments
                                </Typography>
                                {task?.task_comments?.map((item, index) => <>
                                    {item?.comment && <Typography key={item?.id} sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Avatar alt={item?.user?.full_name} src={item?.user?.profile_picture} sx={{ mr: 2 }} />
                                        <Typography variant="body2" color="text.secondary">
                                            {item?.comment}
                                        </Typography>
                                    </Typography>}
                                </>
                                )}
                            </Grid>}
                        </Grid>
                    </CardContent>
                </Card>
            </Container>
        </Page>
    );
}
