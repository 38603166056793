// components
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  analytics: getIcon('ic_analytics'),
  activity: getIcon('ic_activity'),
  banking: getIcon('ic_banking'),
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chart: getIcon('ic_charts'),
  dashboard: getIcon('ic_dashboard'),
  ecommerce: getIcon('ic_ecommerce'),
  invoice: getIcon('ic_invoice'),
  kanban: getIcon('ic_kanban'),
  timesheet: getIcon('time-sheet'),
  user: getIcon('ic_user'),
  fav: getIcon('star_RN')
};

export const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      { title: 'Dashboard', path: '/dashboard', icon: ICONS.dashboard },
      { title: 'Tasks', path: '/tasks', icon: ICONS.chart },
      { title: 'Favourite Tasks', path: '/favourite-tasks', icon: ICONS.fav },
      { title: 'Time Sheet', path: '/time-sheet', icon: ICONS.timesheet },
      { title: 'Logs', path: '/logs', icon: ICONS.invoice },
      { title: 'Review', path: '/review', icon: ICONS.blog },

      // { title: 'Track', path: '/track', icon: ICONS.analytics },
      // { title: 'Reduce', path: '/reduce', icon: ICONS.chart },
      // { title: 'Report', path: '/report', icon: ICONS.invoice },
    ],
  },

  // // MANAGEMENT
  // // ----------------------------------------------------------------------
  // {
  //   subheader: 'management',
  //   items: [
  //     {
  //       title: 'Startups',
  //       path: 'startup',
  //       icon: ICONS.user,
  //       children: [
  //         { title: 'Create Startup', path: 'startup/create-startup' },
  //         { title: 'StartupList', path: 'startup/startup-list' },
  //         // { title: 'Six', path: '/dashboard/user/six' },
  //       ],
  //     },
  //     {
  //       title: 'Projects',
  //       path: 'project',
  //       icon: ICONS.ecommerce,
  //       children: [
  //         { title: 'Create Project', path: 'project/create-project' },
  //         { title: 'Project List', path: 'project/project-list' },
  //       ],
  //     },
  //     {
  //       title: 'user',
  //       path: 'user',
  //       icon: ICONS.user,
  //       children: [
  //         {
  //           title: 'manage users',
  //           path: '/manage-user',
  //           children: [
  //             {
  //               title: 'create user',
  //               path: '/manage-user/create-user',
  //             },
  //             {
  //               title: 'UserList',
  //               path: '/user/user-List',
  //             },
  //             // {
  //             //   title: 'edit user',
  //             //   path: '/manage-user/edit-user',
  //             // },
  //             // {
  //             //   title: 'bulk import users',
  //             //   path: '/manage-user/bulk-import',
  //             // },
  //             // {
  //             //   title: 'manage Teams',
  //             //   path: '/manage-user/manage-team',
  //             // },

  //             // {
  //             //   title: 'Account',
  //             //   path: '/user/user-account',
  //             // },
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       title: 'Teams',
  //       path: 'team/team-list',
  //       icon: ICONS.user,
  //       // children: [
  //       //   { title: 'Create Team', path: 'team/create-team' },
  //       //   { title: 'Team List', path: 'team/team-list' },
  //       // ],
  //     },
  //     {
  //       title: 'Tags',
  //       path: 'tag/tag-list',
  //       icon: ICONS.invoice,
  //       // children: [
  //       //   { title: 'Create Tag', path: 'tag/create-tag' },
  //       //   { title: 'Tag List', path: 'tag/tag-list' },
  //       // ],
  //     },
  //     {
  //       title: 'Roles',
  //       path: 'role/set-role',
  //       icon: ICONS.user,
  //       // children: [
  //       //   { title: 'Set Role', path: 'role/set-role' },
  //       //   { title: 'Role List', path: 'role/role-list' },
  //       // ],
  //     },
  //     {
  //       title: 'Reports',
  //       path: 'reports',
  //       icon: ICONS.chart,
  //     },
  //   ].filter(Boolean),
  // },
];

export const navConfigLead = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      { title: 'Dashboard', path: '/dashboard', icon: ICONS.dashboard },
      { title: 'Team Tasks', path: '/team-tasks', icon: ICONS.analytics },
      { title: 'My Tasks', path: '/tasks', icon: ICONS.chart },
      { title: 'Favourite Tasks', path: '/favourite-tasks', icon: ICONS.fav },
      { title: 'Time Sheet', path: '/time-sheet', icon: ICONS.timesheet },
      { title: 'Logs', path: '/logs', icon: ICONS.invoice },
      { title: 'Review', path: '/review', icon: ICONS.blog },
      { title: 'Tracker', path: '/tracker', icon: ICONS.kanban },
    ],
  },
];

export const navConfigAdmin = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      { title: 'Dashboard', path: '/dashboard', icon: ICONS.dashboard },
      { title: 'Tasks', path: '/tasks', icon: ICONS.chart },
      { title: 'Favourite Tasks', path: '/favourite-tasks', icon: ICONS.fav },
      { title: 'Time Sheet', path: '/time-sheet', icon: ICONS.timesheet },
      { title: 'Logs', path: '/logs', icon: ICONS.invoice },
      { title: 'Review', path: '/review', icon: ICONS.blog },
      { title: 'Tracker', path: '/tracker', icon: ICONS.kanban },

      // { title: 'Track', path: '/track', icon: ICONS.analytics },
      // { title: 'Reduce', path: '/reduce', icon: ICONS.chart },
      // { title: 'Report', path: '/report', icon: ICONS.invoice },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    items: [
      {
        title: 'Startups',
        path: 'startup',
        icon: ICONS.user,
        children: [
          { title: 'Create Startup', path: 'startup/create-startup' },
          { title: 'StartupList', path: 'startup/startup-list' },
          // { title: 'Six', path: '/dashboard/user/six' },
        ],
      },
      {
        title: 'Projects',
        path: 'project',
        icon: ICONS.ecommerce,
        children: [
          { title: 'Create Project', path: 'project/create-project' },
          { title: 'Project List', path: 'project/project-list' },
        ],
      },
      {
        title: 'Activities',
        path: 'activities',
        icon: ICONS.activity,
        // children: [
        //   { title: 'Create Startup', path: 'startup/create-startup' },
        //   { title: 'StartupList', path: 'startup/startup-list' },
        //   // { title: 'Six', path: '/dashboard/user/six' },
        // ],
      },
      {
        title: 'user',
        path: 'user',
        icon: ICONS.user,
        children: [
          {
            title: 'create user',
            path: '/manage-user/create-user',
          },
          {
            title: 'UserList',
            path: '/user/user-List',
          },
        ],
      },
      {
        title: 'Reports',
        path: 'reports',
        icon: ICONS.chart,
      },
    ].filter(Boolean),
  },

  // SETTINGS
  // ----------------------------------------------------------------------
  {
    subheader: 'Settings',
    items: [
      {
        title: 'Teams',
        path: 'settings/team-list',
        icon: ICONS.user,
      },
      // {
      //   title: 'Tags',
      //   path: 'settings/tag-list',
      //   icon: ICONS.invoice,
      // },
      {
        title: 'Roles',
        path: 'settings/role-list',
        icon: ICONS.user,
      },
      {
        title: 'Project Status',
        path: 'settings/project-status',
        icon: ICONS.analytics,
      },
      {
        title: 'Task Status',
        path: 'settings/task-status',
        icon: ICONS.ecommerce,
      },
      // {
      //   title: 'Task Assigned Status',
      //   path: 'settings/task-assigned-status',
      //   icon: ICONS.dashboard,
      // },
      {
        title: 'Task Priorities',
        path: 'settings/task-priorities',
        icon: ICONS.invoice,
      },
    ],
  },
];
