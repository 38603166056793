import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Box, Button, Card, CardContent, Container, Divider, Grid, Typography } from '@mui/material';
// hooks
// eslint-disable-next-line import/no-unresolved
import { useSelector } from 'react-redux';
import LoadingScreen from 'src/components/LoadingScreen';
import AxiosAll from 'src/services/axiosAll';
import useSettings from '../../hooks/useSettings';
// components
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Page from '../../components/Page';

const Profile = () => {
  const { themeStretch } = useSettings();
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { userDetails } = useSelector((state) => state.loginReducer);
  const { token } = userDetails;

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        setLoading(true);
        const response = await AxiosAll('get', `/profile`, null, token);
        const { data } = response;
        setProfileData(data?.data);
        setLoading(false);
      } catch (err) {
        console.log('err', err);
        setLoading(false);
      }
    };
    fetchProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // loading skeleton
  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <Page title="Profile">
      <Container maxWidth="lg">
        <HeaderBreadcrumbs heading="Profile" links={[{ name: 'Dashboard', href: '/dashboard' }, { name: 'Profile' }]} />
        <Card>
          <Typography variant="h4" sx={{ p: 3 }}>
            Profile
          </Typography>
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={4}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'center',
                    py: 3,
                  }}
                >
                  <Box
                    component="img"
                    alt="profile"
                    src={profileData?.profile_picture}
                    sx={{
                      width: 128,
                      height: 128,
                      borderRadius: '50%',
                      objectFit: 'cover',
                    }}
                  />
                  <Typography variant="h5" sx={{ mt: 3, mb: 0.5 }}>
                    {profileData?.first_name} {profileData?.last_name}
                  </Typography>
                  <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                    {profileData?.position}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    {profileData?.email}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={8}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 3 }}>
                  <Button
                    onClick={() => navigate('/profile/edit', { state: { currentUser: profileData } })}
                    variant="contained"
                  >
                    Edit Profile
                  </Button>
                </Box>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                      First Name
                    </Typography>
                    <Typography variant="body2">{profileData?.first_name}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                      Last Name
                    </Typography>
                    <Typography variant="body2">{profileData?.last_name}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                      Email
                    </Typography>
                    <Typography variant="body2">{profileData?.email}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                      Position
                    </Typography>
                    <Typography variant="body2">{profileData?.position}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                      Status
                    </Typography>
                    <Typography variant="body2">{profileData?.status}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                      Last Active
                    </Typography>
                    <Typography variant="body2">{profileData?.last_active_time}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Grid container spacing={3} sx={{ mt: 3 }}>
          <Grid item xs={12} md={6}>
            <Card>
              <Typography variant="h4" sx={{ p: 3 }}>
                Teams
              </Typography>
              <Divider />
              <CardContent>
                <Grid container spacing={3}>
                  {profileData?.teams?.map((team) => (
                    <Grid item xs={12} md={6} key={team.id}>
                      <Typography variant="body2">{team.name}</Typography>
                    </Grid>
                  ))}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card>
              <Typography variant="h4" sx={{ p: 3 }}>
                Roles
              </Typography>
              <Divider />
              <CardContent>
                <Grid container spacing={3}>
                  {profileData?.roles?.map((role) => (
                    <Grid item xs={12} md={6} key={role.id}>
                      <Typography variant="body2">{role.name}</Typography>
                    </Grid>
                  ))}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Profile;
