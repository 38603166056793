import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import { MenuItem, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// components
// eslint-disable-next-line import/no-unresolved
import { useNavigate } from 'react-router';
// eslint-disable-next-line import/no-unresolved
import moment from 'moment';
import ReviewDialog from 'src/components/dailogs/AssignDialog';
import CompleteDialog from 'src/components/dailogs/CompleteDialog';
import StartDialog from 'src/components/dailogs/StartDialog';
import TimeDailogs from 'src/components/dailogs/Timelogdailogs';
import Iconify from 'src/components/Iconify';
import Label from '../../../../components/Label';
import { TableMoreMenu } from '../../../../components/table';

// ----------------------------------------------------------------------

DeveloperTaskRow.propTypes = {
    row: PropTypes.object,
    onEditRow: PropTypes.func,
    onAddSub: PropTypes.func,
    // selected: PropTypes.bool,
    // onSelectRow: PropTypes.func,
    // setDeleteId: PropTypes.func,
    // setDeleteDialog: PropTypes.func,
    // setMultipleDeleteMode: PropTypes.func
};

export default function DeveloperTaskRow({ row, getTasks, addToFavourite }) {
    const theme = useTheme();
    const navigate = useNavigate();
    const { title, task_priority, project, due_date, spend_time_format, parent_task, is_favorite, task_status, planned_time_format, is_due, created_by_user } = row;

    const [openMenu, setOpenMenuActions] = useState(null);

    const handleOpenMenu = (event) => {
        setOpenMenuActions(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setOpenMenuActions(null);
        // setMultipleDeleteMode(false);
    };

    return (
        <TableRow>
            {/* <TableCell padding="checkbox">
                <Checkbox checked={selected} onClick={onSelectRow} />
            </TableCell> */}

            {is_due ? <TableCell
                align="left"
                variant="subtitle2"
                sx={{ cursor: 'pointer' }}
                onClick={() => navigate(`/tasks/details/${row?.id}`)}
                noWrap>
                <Label
                    variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                    color={'error'}
                    sx={{ textTransform: 'capitalize', height: 'auto', whiteSpace: 'normal', lineHeight: '1.5' }}
                >
                    {title}
                </Label>
            </TableCell> : <TableCell
                align="left"
                variant="subtitle2"
                sx={{ cursor: 'pointer' }}
                onClick={() => navigate(`/tasks/details/${row?.id}`)}
                noWrap>
                {title}
            </TableCell>}

            <TableCell align="left">{project?.title}</TableCell>

            <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
                {moment(due_date).format("D MMM")}
            </TableCell>

            <TableCell align="center">
                <Label
                    variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                    color={task_priority?.color}
                    sx={{ textTransform: 'capitalize' }}
                >
                    {task_priority?.name}
                </Label>
            </TableCell>

            {task_status?.id === 4 ? <TableCell align="left">
                <Tooltip title={row?.reviewer?.full_name}>

                    <Typography
                        variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                        color={task_status?.color}
                        sx={{ textTransform: 'capitalize' }}
                    >
                        {/* {task_status?.name} */}
                        <Label
                            variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                            color={task_status?.color}
                            sx={{ textTransform: 'capitalize' }}
                        >
                            {task_status?.name}
                        </Label>
                    </Typography>
                </Tooltip>
            </TableCell> :
                <TableCell align="left">
                    <Label
                        variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                        color={task_status?.color}
                        sx={{ textTransform: 'capitalize' }}
                    >
                        {task_status?.name}
                    </Label>
                </TableCell>
            }
            <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
                {created_by_user?.full_name}
            </TableCell>

            <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
                {planned_time_format}
            </TableCell>

            <TableCell align='left'>{spend_time_format}</TableCell>
            {/* {(row?.parent_task_id !== null && row?.parent_task_id !== 0) ? <TableCell align="left" sx={{ cursor: 'pointer' }} onClick={() => navigate(`/tasks/details/${row?.parent_task_id}`)} noWrap>{parent_task?.title}</TableCell> : <TableCell />} */}

            <TableCell align="right">
                <TableMoreMenu
                    open={openMenu}
                    onOpen={handleOpenMenu}
                    onClose={handleCloseMenu}
                    actions={
                        <>
                            {/* <MenuItem
                                onClick={() => {
                                    // onDeleteRow();
                                    setDeleteDialog(true);
                                    setDeleteId(row.id);
                                    handleCloseMenu();
                                }}
                                sx={{ color: 'error.main' }}
                            >
                                <Iconify icon={'eva:trash-2-outline'} />
                                Delete
                            </MenuItem> */}

                            {task_status?.id === 1 &&
                                <MenuItem>
                                    <StartDialog task={row} getTasks={getTasks} />
                                </MenuItem>}

                            {task_status?.id === 2 &&
                                <MenuItem>
                                    <ReviewDialog task={row} getTasks={getTasks} />
                                </MenuItem>}

                            {task_status?.id === 2 &&
                                <MenuItem>
                                    <CompleteDialog task={row} getTasks={getTasks} />
                                </MenuItem>}

                            {/* If task assigned_status is 1(not started) then no time log */}
                            {(task_status?.id !== 1 && row?.has_siblings !== 1) &&
                                <MenuItem>
                                    <TimeDailogs task={row} getTasks={getTasks} />
                                </MenuItem>
                            }
                            <MenuItem
                                onClick={() => {
                                    addToFavourite();
                                    handleCloseMenu();
                                }}
                            >
                                <Iconify icon={'ic:round-star-border'} />
                                {is_favorite === 1 ? "Unfavourite" : "Favourite"}
                            </MenuItem>
                        </>
                    }
                />
            </TableCell>
        </TableRow>
    );
}
