import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
// eslint-disable-next-line import/no-unresolved
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import AxiosAll from 'src/services/axiosAll';
import * as Yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import InputMask from 'react-input-mask';
import { FormProvider, RHFEditor, RHFTextField } from '../hook-form';
import Iconify from '../Iconify';

// --------------------------------------------------------

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

TimeDailogs.propTypes = {
  task: PropTypes.object,
  getTasks: PropTypes.func,
};
export default function TimeDailogs({ task, getTasks }) {
  const { userDetails } = useSelector((state) => state.loginReducer);
  const { token } = userDetails;
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);

  const NewAddressSchema = Yup.object().shape({
    time: Yup.string().min(5, 'Must be in the form of HH:MM').required('Time is required'),
    comments: Yup.string().required('Comments  is required'),
  });

  const handleClickOpen = () => {
    setOpen(true);
  };
  const defaultValues = {
    time: task?.planned_time_format,
    comments: '',
    date: moment(new Date()).format('YYYY-MM-DD'),
  };
  const methods = useForm({
    resolver: yupResolver(NewAddressSchema),
    defaultValues,
  });
  const {
    reset,
    watch,
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch()

  const handleOnClose = () => {
    reset(defaultValues)
    setOpen(false)
  }

  const handleTimeChange = (e, onChange) => {
    const value = e.target.value;
    // increase hours by 1 if minutes are greater than 60
    if (value.split(':')[1] > 59) {
      // if hours is less than 10, add 0 before it
      const hours =
        parseInt(value.split(':')[0], 10) + 1 < 10
          ? `0${parseInt(value.split(':')[0], 10) + 1}`
          : parseInt(value.split(':')[0], 10) + 1;
      // if minutes is less than 10, add 0 before it
      const minutes =
        parseInt(value.split(':')[1], 10) - 60 < 10
          ? `0${parseInt(value.split(':')[1], 10) - 60}`
          : parseInt(value.split(':')[1], 10) - 60;
      onChange(`${hours}:${minutes}`);
    } else {
      onChange(value);
    }
  };
  const onSubmit = async (value) => {
    // eslint-disable-next-line no-debugger
    try {
      const data1 = {
        task_id: task?.id,
        comment: value?.comments,
        time_format: value?.time,
        date: value?.date,
      };
      const response = await AxiosAll('post', '/task-time-logs', data1, token);
      const { data } = response;

      if (data.statusCode === 200) {
        enqueueSnackbar(data?.message, { variant: 'success' });
        setOpen(false);
        getTasks();
      } else {
        enqueueSnackbar(data?.message, { variant: 'error' });
      }
    } catch (error) {
      enqueueSnackbar(error?.data?.message, { variant: 'error' });
    }
  };
  return (
    <div>
      <Button className="timeBTn" onClick={handleClickOpen}>
        <Iconify icon={'ic:sharp-more-time'} />
        Time log
      </Button>
      <Dialog fullWidth maxWidth="sm" open={open} onClose={handleOnClose}>
        <DialogTitle>Create time log</DialogTitle>

        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Stack spacing={2}>

              <div>
                <LabelStyle>Time Taken</LabelStyle>
                <Controller
                  name="time"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <InputMask maskChar={null} mask="99:99" value={field.value} onChange={(e) => handleTimeChange(e, field.onChange)}>
                      {(inputProps) => (
                        <TextField
                          {...inputProps}
                          error={!!error}
                          fullWidth
                          helperText={error?.message}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Typography variant="body2">HH:MM</Typography>
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    </InputMask>
                  )}
                />
              </div>
              <div>
                <LabelStyle>Date</LabelStyle>
                <RHFTextField name="date" type="date" />
              </div>
              <div>
                <LabelStyle>Description</LabelStyle>
                {/* <RHFTextField name="description" label="Write Something Awesome..." multiline rows={4} /> */}
                <RHFEditor simple name="comments" />
              </div>

            </Stack>
          </DialogContent>

          <DialogActions>
            <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
              Submit
            </LoadingButton>
            <Button color="inherit" variant="outlined" onClick={handleOnClose}>
              Cancel
            </Button>
          </DialogActions>
        </FormProvider>
      </Dialog>
    </div>
  );
}
