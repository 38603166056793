import PropTypes from 'prop-types';
import * as Yup from 'yup';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// @mui
import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Stack, Typography } from '@mui/material';
// eslint-disable-next-line import/no-unresolved
import { styled } from '@mui/material/styles';
// _mock
import { useSnackbar } from 'notistack';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
// eslint-disable-next-line import/no-unresolved
import AxiosAll from 'src/services/axiosAll';
import { FormProvider, RHFSelect, RHFTextField } from '../hook-form';
import Iconify from '../Iconify';


// ----------------------------------------------------------------------

const LabelStyle = styled(Typography)(({ theme }) => ({
    ...theme.typography.subtitle2,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1),
}));

ReviewDialog.propTypes = {
    task: PropTypes.object,
    getTasks: PropTypes.func,
};

export default function ReviewDialog({ task, getTasks }) {
    const { userDetails } = useSelector((state) => state.loginReducer);
    const { token } = userDetails;
    const [userData, setUserData] = useState([]);
    const { enqueueSnackbar } = useSnackbar();
    const [open, setOpen] = useState(false);
    // console.log(userDetails?.user?.id);
    const NewTeamSchema = Yup.object().shape({
        // time: Yup.string().required('Time is required'),
        // description: Yup.string().required('Description is required'),
        // status: Yup.string().required('Status is required'),
    });

    const handleClickOpen = () => {
        // eslint-disable-next-line no-unused-expressions
        userData && setOpen(true);
    };
    const defaultValues = useMemo(
        () => ({
            userId: userData[0]?.id,
            comment: ''
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [userData]
    );

    const methods = useForm({
        resolver: yupResolver(NewTeamSchema),
        defaultValues,
    });

    const {
        handleSubmit,
        watch,
        reset,
        setValue,
        formState: { isSubmitting },
    } = methods;

    const values = watch();

    const getUser = async () => {
        const res = await AxiosAll('get', `/common/users`, {}, token);
        if (res?.status === 200) {
            const newData = res?.data;
            setUserData(newData);
        }
    };
    useEffect(() => {
        // eslint-disable-next-line no-unused-expressions
        getUser();
    }, [])

    useEffect(() => {
        reset(defaultValues);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSubmit = async () => {
        try {
            const data1 = {
                task_status: '4',
                reviewer_id: values?.userId,
                reviewer_comment: values?.comment,
                reviewer_status: 0,
            };

            const response = await AxiosAll('post', `/tasks/status-change/${task?.id}`, data1, token);

            const { data } = response;

            if (data.statusCode === 200) {
                enqueueSnackbar(data?.message, { variant: 'success' });
                setOpen(false);
                getTasks();
                reset();
            } else {
                enqueueSnackbar(data?.message, { variant: 'error' });
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div>

            <Button className="timeBTn" onClick={handleClickOpen}>
                <Iconify icon={'pajamas:assignee'} />
                In Review
            </Button>

            <Dialog
                fullWidth
                maxWidth="sm"
                open={open}
                onClose={() => {
                    setOpen(false);
                    reset(defaultValues);
                }}
            >
                <DialogTitle>Assign this task for review</DialogTitle>

                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                    <DialogContent>
                        <Stack spacing={3}>
                            <div>
                                <LabelStyle>Select Reviwer</LabelStyle>
                                <RHFSelect name="userId">
                                    {userData?.map((item, index) => (userDetails?.user?.id !== item?.id && <option value={item?.id} key={index}>{`${item?.first_name} ${item?.last_name}`}</option>))}
                                </RHFSelect>
                                <div>
                                    <LabelStyle>Comments</LabelStyle>
                                    <RHFTextField name="comment" placeholder="Write something..." multiline rows={4} />
                                </div>
                            </div>


                        </Stack>
                    </DialogContent>

                    <Divider />

                    <DialogActions>
                        <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                            Submit
                        </LoadingButton>
                        <Button
                            color="inherit"
                            variant="outlined"
                            onClick={() => {
                                setOpen(false);
                                reset(defaultValues);
                            }}
                        >
                            Cancel
                        </Button>
                    </DialogActions>
                </FormProvider>
            </Dialog>
        </div>

    );
}
