import PropTypes from 'prop-types';
import * as Yup from 'yup';
// form
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, InputAdornment, Stack, TextField, Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
// _mock
import { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-unresolved
import moment from 'moment';
import InputMask from 'react-input-mask';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import AxiosAll from 'src/services/axiosAll';
import { FormProvider, RHFEditor, RHFSelect, RHFTextField } from '../../components/hook-form';

// ----------------------------------------------------------------------

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

AddLogsForm.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onNextStep: PropTypes.func,
  onCreateBilling: PropTypes.func,
  editTask: PropTypes.object,
};

export default function AddLogsForm({ open, onClose, editTask }) {
  const NewAddressSchema = Yup.object().shape({
    time: Yup.string().min(5, 'Must be in the form of HH:MM').required('Time is required'),
    comments: Yup.string().required('Description  is required'),
    date: Yup.string().required('date is required'),
    // task: Yup.string().required('task is required'),
    // type: Yup.string().required('type is required'),
  });

  const typeData = [
    { id: 'discussion', title: 'Discussion' },
    { id: 'internal_meeting', title: 'Internal Meeting' },
    { id: 'external_meeting', title: 'External Meeting' },
    { id: 'holiday', title: 'Holiday' },
    { id: 'break', title: 'Break' },
    { id: 'celebration', title: 'Celebration' },
    { id: 'down_time', title: 'Down Time' },
  ]
  const [checked, setChecked] = useState(false);

  const defaultValues = {
    time: '01:00',
    comments: '',
    date: moment(new Date()).format('YYYY-MM-DD'),
    // task: '',
    project: '',
    type: "discussion"
  };


  const { userDetails } = useSelector((state) => state.loginReducer);
  const { token } = userDetails;
  const [project, setProject] = useState([]);
  const [tasks, setTask] = useState([]);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const methods = useForm({
    resolver: yupResolver(NewAddressSchema),
    defaultValues,
  });
  const {
    reset,
    watch,
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch()

  const handleOnClose = () => {
    reset(defaultValues)
    onClose()
  }

  const handleTimeChange = (e, onChange) => {
    const value = e.target.value;
    // increase hours by 1 if minutes are greater than 60
    if (value.split(':')[1] > 59) {
      // if hours is less than 10, add 0 before it
      const hours =
        parseInt(value.split(':')[0], 10) + 1 < 10
          ? `0${parseInt(value.split(':')[0], 10) + 1}`
          : parseInt(value.split(':')[0], 10) + 1;
      // if minutes is less than 10, add 0 before it
      const minutes =
        parseInt(value.split(':')[1], 10) - 60 < 10
          ? `0${parseInt(value.split(':')[1], 10) - 60}`
          : parseInt(value.split(':')[1], 10) - 60;
      onChange(`${hours}:${minutes}`);
    } else {
      onChange(value);
    }
  };

  const onSubmit = async (value) => {

    // eslint-disable-next-line no-debugger
    const data = {
      comment: value?.comments,
      // task_id: value?.task || '',
      date: value?.date,
      time_format: value?.time,
      type: value?.type,
      project: value?.project
    };
    try {
      const response = await AxiosAll('post', `/task-time-logs`, data, token);
      if (response?.status === 200) {
        handleOnClose()
        reset(defaultValues)
        navigate('/logs');
      } else {
        enqueueSnackbar(response?.data?.message, { variant: 'error' });
      }
    } catch (error) {
      enqueueSnackbar(error?.data?.message, { variant: 'error' });
    }
  };

  const getProject = async () => {
    const response = await AxiosAll('get', '/common/projects', {}, token);
    if (response?.status === 200) {
      setProject(response?.data);
    }
  };

  const getTask = async () => {
    const response = await AxiosAll('get', `/common/tasks?project_id=${values?.project || ''}`, {}, token);
    if (response?.status === 200) {
      setTask(response?.data);
    }
  };

  useEffect(() => {
    getProject();
  }, [open]);

  useEffect(() => {
    // eslint-disable-next-line no-debugger, no-unused-expressions
    values?.project && getTask();
  }, [values?.project, project]);

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={handleOnClose}>
      <DialogTitle> {editTask ? "Edit Log" : "Add Log"}</DialogTitle>

      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Stack spacing={3}>
            <div>
              <LabelStyle>Type</LabelStyle>
              <RHFSelect disabled={!!editTask} name="type" >
                {/* <option value="">Select Project</option> */}
                {typeData?.map((data, index) => (
                  <option key={index} value={data?.id}>
                    {data?.title}
                  </option>
                ))}
              </RHFSelect>
            </div>

            {(values?.type === "discussion" || values?.type === "external_meeting") && <div>
              <LabelStyle>Project*</LabelStyle>
              <RHFSelect disabled={!!editTask} name="project" >
                {/* <option value="">Select Project</option> */}
                {project?.map((data, index) => (
                  <option key={index} value={data?.id}>
                    {data?.title}
                  </option>
                ))}
              </RHFSelect>
            </div>}

            {/* <div>
              <LabelStyle>Task*</LabelStyle>
              <RHFSelect
                fullWidth
                disabled={!!editTask}
                name="task"
                label="task"
                InputLabelProps={{ shrink: true }}
                SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
              >
                {tasks?.map((data) => (
                  <MenuItem
                    key={data?.id}
                    value={data?.id}
                    sx={{
                      mx: 1,
                      my: 0.5,
                      borderRadius: 0.75,
                      typography: 'body2',
                      textTransform: 'capitalize',
                    }}
                  >
                    {data?.title}
                  </MenuItem>
                ))}
              </RHFSelect>
            </div> */}
            {/* <div>
              <LabelStyle>Task*</LabelStyle>
              <RHFTextField name="task" label="Please describe task.." />
            </div> */}
            <div>
              <LabelStyle>Date*</LabelStyle>
              <RHFTextField name="date" type="date" />
            </div>

            <div>
              <LabelStyle>Time Taken*</LabelStyle>
              <Controller
                name="time"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <InputMask maskChar={null} mask="99:99" value={field.value} onChange={(e) => handleTimeChange(e, field.onChange)}>
                    {(inputProps) => (
                      <TextField
                        {...inputProps}
                        error={!!error}
                        fullWidth
                        helperText={error?.message}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Typography variant="body2">HH:MM</Typography>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  </InputMask>
                )}
              />
            </div>
            <div>
              <LabelStyle>Description</LabelStyle>
              {/* <RHFTextField name="description" label="Write Something Awesome..." multiline rows={4} /> */}
              <RHFEditor simple name="comments" />
            </div>
          </Stack>
        </DialogContent>

        <DialogActions>
          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            Submit
          </LoadingButton>
          <Button color="inherit" variant="outlined" onClick={handleOnClose}>
            Cancel
          </Button>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
