import { combineReducers } from 'redux';
import dashBoardReducer from './dashboardReducer';
import LoginReducer from './loginReducer';

const rootReducer = combineReducers({
  loginReducer: LoginReducer,
  // eslint-disable-next-line object-shorthand
  dashBoardReducer: dashBoardReducer,
});

export default rootReducer;
