/* eslint-disable import/order */
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import {
  Box,
  Card,
  Container,
  Divider,
  FormControlLabel,
  Switch,
  Table,
  TableBody,
  TableContainer,
  TablePagination
} from '@mui/material';
// routes
// import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import { useSelector } from 'react-redux';
// eslint-disable-next-line import/no-unresolved
import { useSnackbar } from 'notistack';
// eslint-disable-next-line import/no-unresolved
import AxiosAll from 'src/services/axiosAll';
import useSettings from '../../hooks/useSettings';
import useTable from '../../hooks/useTable';
import useTabs from '../../hooks/useTabs';
// _mock_
// components
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import { TableHeadCustom, TableNoData, TableSkeleton } from '../../components/table';
// sections
import { TaskTableToolbar } from '../../sections/@dashboard/task/list';
// eslint-disable-next-line import/no-unresolved
import ReviewTaskRow from 'src/sections/@dashboard/task/list/ReviewTaskRow';

// ----------------------------------------------------------------------

const STATUS_OPTIONS = ['all', 'active', 'banned'];

const ROLE_OPTIONS = [
  'all',
  'ux designer',
  'full stack designer',
  'backend developer',
  'project manager',
  'leader',
  'ui designer',
  'ui/ux designer',
  'front end developer',
  'full stack developer',
];

const TABLE_HEAD = [
  { id: 'title', label: 'Title', align: 'left' },
  { id: 'project', label: 'Project', align: 'left' },
  { id: 'dueDate', label: 'Due Date', align: 'left' },
  { id: 'priority', label: 'priority', align: 'center' },
  { id: 'status', label: 'Status', align: 'left' },
  { id: 'dev', label: 'Developer', align: 'left' },
  { id: 'parent', label: 'Alloted Time', align: 'left' },
  { id: 'action', label: 'Action', align: 'left' },
];

export default function ReviewTask() {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const { themeStretch } = useSettings();

  const navigate = useNavigate();
  // get redux state
  const { userDetails } = useSelector((state) => state.loginReducer);
  const { token } = userDetails;

  const [tableData, setTableData] = useState([]);

  const [filterTitle, setFilterTitle] = useState('');

  const [filterDeadline, setFilterDeadline] = useState('all');

  const { currentTab: filterStatus, onChangeTab: onChangeFilterStatus } = useTabs('all');
  const [loading, setLoading] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [count, setCount] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const [multipleDeleteMode, setMultipleDeleteMode] = useState(false);

  const handleFilterName = (filterTitle) => {
    setFilterTitle(filterTitle);
    setPage(0);
  };

  const handleFilterRole = (event) => {
    setFilterDeadline(event.target.value);
  };

  const getTasks = async () => {
    setLoading(true);
    // eslint-disable-next-line no-template-curly-in-string
    const response = await AxiosAll('get', `/tasks?type=reviewers_task&page=${page + 1}&query=${filterTitle}`, {}, token);
    const { data } = response;

    if (data?.statusCode === 200) {
      setLoading(false);
      setTableData(data?.data?.data);
      setCount(response?.data?.data?.total);
    }
  };

  useEffect(() => {
    getTasks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, filterTitle]);

  // const handleConfirmDelete = () => {
  //     if (selected.length > 0 && multipleDeleteMode) {
  //         handleDeleteRows(selected);
  //     } else {
  //         handleDeleteRow(deleteId);
  //     }
  //     setDeleteDialog(false);
  // };

  // const handleDeleteRow = async (id) => {
  //     const res = await AxiosAll('delete', `/tasks/${id}`, null, token)
  //     const { data } = res;
  //     if (data?.statusCode === 200) {
  //         enqueueSnackbar(data?.message, { variant: 'success' });
  //         getTasks();
  //     }
  //     else if (data?.statusCode === 419) {
  //         enqueueSnackbar(data?.message, { variant: 'error' });
  //     }
  // };

  // // bulk delete is yet to complete.
  // const handleDeleteRows = async (selected) => {
  //     const response = await AxiosAll('delete', `/tasks`, { ids: selected }, token);
  //     const { data } = response;
  //     if (data?.statusCode === 200) {
  //         enqueueSnackbar(data?.message, { variant: 'success' });
  //         setSelected([]);
  //         getTasks();
  //     } else {
  //         enqueueSnackbar(data?.message, { variant: 'error' });
  //     }
  // };

  const handleEditRow = (id) => {
    navigate(`/tasks/edit/${id}`);
  };
  const handleAddSub = (id) => {
    navigate(`/tasks/add-sub/${id}`);
  };

  const denseHeight = dense ? 52 : 72;

  const isNotFound =
    (!tableData.length && !!filterTitle) ||
    (!tableData.length && !!filterDeadline) ||
    (!tableData.length && !!filterStatus);

  return (
    <Page title="User: List">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Review List"
          links={[{ name: 'Dashboard', href: '/dashboard' }, { name: 'Tasks' }]}
        />

        <Card>
          <Divider />
          {/* <ConfirmDialogue
                        open={deleteDialog}
                        loading={loading}
                        onClose={() => setDeleteDialog(false)}
                        onConfirm={() => handleConfirmDelete(deleteId)}
                        title={`Are you sure you want to delete ${multipleDeleteMode && selected.length > 1 ? 'these tasks' : 'this task'
                            }?`}
                        content="This action cannot be undone. Please confirm to proceed."
                    /> */}
          <TaskTableToolbar
            filterTitle={filterTitle}
            filterDeadline={filterDeadline}
            onFilterName={handleFilterName}
            onFilterRole={handleFilterRole}
            optionsRole={ROLE_OPTIONS}
            search="task"
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>

              <Table size={dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  hideCheckbox
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData.length}
                  numSelected={selected.length}
                  onSort={onSort}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      tableData.map((row) => row.id)
                    )
                  }
                />
                {loading ? (
                  <>
                    <TableSkeleton />
                    <TableSkeleton />
                    <TableSkeleton />
                    <TableSkeleton />
                    <TableSkeleton />
                  </>
                ) : (
                  <TableBody>
                    {tableData.map((row) => (
                      <ReviewTaskRow
                        key={row.id}
                        row={row}
                        onEditRow={() => handleEditRow(row.id)}
                        onAddSub={() => handleAddSub(row?.id)}
                        getTasks={getTasks}
                      />
                    ))}
                    <TableNoData isNotFound={isNotFound} />
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <Box sx={{ position: 'relative' }}>
            <TablePagination
              rowsPerPageOptions={[20]}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
            />

            <FormControlLabel
              control={<Switch checked={dense} onChange={onChangeDense} />}
              label="Dense"
              sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
            />
          </Box>
        </Card>
      </Container>
    </Page>
  );
}
