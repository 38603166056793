import PropTypes from 'prop-types';
import * as Yup from 'yup';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// @mui
import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Stack, Typography } from '@mui/material';
// eslint-disable-next-line import/no-unresolved
import { styled } from '@mui/material/styles';
// _mock
import { useSnackbar } from 'notistack';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
// eslint-disable-next-line import/no-unresolved
import AxiosAll from 'src/services/axiosAll';
import { FormProvider, RHFSelect, RHFTextField } from '../hook-form';
import Iconify from '../Iconify';

// ----------------------------------------------------------------------

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

StatusDialog.propTypes = {
  task: PropTypes.object,
  getTasks: PropTypes.func,
};

export default function StatusDialog({ task, getTasks }) {
  const { userDetails } = useSelector((state) => state.loginReducer);
  const { token } = userDetails;
  const [userData, setUserData] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);

  const NewTeamSchema = Yup.object().shape({
    // time: Yup.string().required('Time is required'),
    // description: Yup.string().required('Description is required'),
    // status: Yup.string().required('Status is required'),
  });

  const handleClickOpen = () => {
    setOpen(true);
  };
  const defaultValues = useMemo(
    () => ({
      statusId: '2',
      comment: '',
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userData]
  );

  const methods = useForm({
    resolver: yupResolver(NewTeamSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  const getUser = async () => {
    const res = await AxiosAll('get', `/task-statuses`, {}, token);
    if (res?.status === 200) {
      const newData = res?.data?.data;
      setUserData(newData);
    }
  };
  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    userData && getUser();
  }, []);

  useEffect(() => {
    reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //   // console.log("values", values);

  const onSubmit = async () => {
    const id = values?.statusId === '5' ? 1 : 0;
    try {
      const data1 = {
        task_status: values?.statusId,
        reviewer_id: task?.reviewer_id,
        reviewer_comment: values?.comment,
        reviewer_status: id,
      };

      const response = await AxiosAll('post', `/tasks/status-change/${task?.id}`, data1, token);

      const { data } = response;

      if (data.statusCode === 200) {
        enqueueSnackbar(data?.message, { variant: 'success' });
        setOpen(false);
        getTasks();
        reset();
      } else {
        enqueueSnackbar(data?.message, { variant: 'error' });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <Button className="timeBTn" onClick={handleClickOpen}>
        <Iconify icon={'pajamas:assignee'} />
        Reassign{' '}
      </Button>

      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        onClose={() => {
          setOpen(false);
          reset(defaultValues);
        }}
      >
        <DialogTitle>Reassign this task for review</DialogTitle>

        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Stack spacing={3}>
              <div>
                <LabelStyle>Select Status</LabelStyle>
                <RHFSelect name="statusId">
                  <option value={'2'}>{`Reassign `}</option>
                  <option value={'5'}>{`Complete `}</option>
                </RHFSelect>
                <div>
                  <LabelStyle>Comments</LabelStyle>
                  <RHFTextField name="comment" placeholder="Write something..." multiline rows={4} />
                </div>
              </div>
            </Stack>
          </DialogContent>

          <Divider />

          <DialogActions>
            <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
              Submit
            </LoadingButton>
            <Button
              color="inherit"
              variant="outlined"
              onClick={() => {
                setOpen(false);
                reset(defaultValues);
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </FormProvider>
      </Dialog>
    </div>
  );
}
